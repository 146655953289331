import Loader from "components/Common/Loader";
import TableView from "components/Common/TableView";
import useFilter from "components/Common/useFilter";
import {
  decrypt,
  getFormId,
  parseJwt,
  urlValidation,
} from "helpers/common_helper";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getPartnerAction, getReportAction } from "store/actions";

const ReportList = props => {
  const [products, setProducts] = useState([]);
  const [userTokenDel, setUserTokenDel] = useState([]);
  const [partner, setPartner] = useState([]);
  const formId = getFormId();
  const auth = urlValidation(formId);
  const [filter, handelFilter] = useFilter(6);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });

  useEffect(async () => {
    try {
      props.setLoading(true);
      const encodedQueryString = decrypt(filter);
      const response = await getReportAction("", encodedQueryString);
      if (response.status === 1) {
        setServerPagination({ ...response.metadata });
        setProducts(response.data);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [filter]);

  useEffect(async () => {
    if (userTokenDel.masterId == "") {
      try {
        props.setLoading(true);
        const response2 = await getPartnerAction();
        if (response2.status === 1) {
          let arr = response2?.data || [];
          setPartner(arr);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response2.msg);
        }
      } catch (error) {
        props.setLoading(false);
        console.log(error);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [userTokenDel]);

  useEffect(async () => {
    let authUser = localStorage.getItem("authUser");
    authUser = authUser ? JSON.parse(authUser) : {};

    if (authUser) {
      let authToken = authUser?.authToken || "";
      let partner = authUser?.partner || "";
      if (authToken) {
        const userDetails = parseJwt(authToken);
        setUserTokenDel(userDetails);
      }
    }
  }, []);

  const filterFields = [];

  if (!userTokenDel.masterId && partner.length > 0) {
    let partnerOptions = partner.map(val => {
      return {
        value: val.id,
        label: val.companyName,
      };
    });
    partnerOptions.unshift({ value: "", label: "All" });
    filterFields.push({
      dataField: "partnerId",
      text: "Partner Name",
      placeholder: "Partner Name",
      filter: partnerOptions,
      defaultValue: "",
    });
  }
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "View Name",
    },
    {
      dataField: "partnerName",
      text: "Partner Name",
      formatter: data => {
        return !data ? <b>FOR ALL PARTNER</b> : data;
      },
    },
    {
      dataField: "uniqueCode",
      text: "Unique Code",
    },

    {
      dataField: "viewImage",
      text: "View Image",
      formatter: data => {
        return data.includes("noimage.png") ? (
          <b>No Image</b>
        ) : (
          <a
            href={data}
            target="_blank"
            rel="noreferrer"
            className="d-flex align-items-center gap-2"
          >
            Image <span className="material-icons-outlined">image</span>
          </a>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Created",
      sort: true,
      formatter: data => {
        return data.slice(0, 10).split("-").reverse().join("/");
      },
    },
    {
      dataField: "updatedAt",
      text: "Updated",
      sort: true,
      formatter: data => {
        return data.slice(0, 10).split("-").reverse().join("/");
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: data => {
        return data === 1 ? (
          <span className=" badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger btn-rounded">Inactive</span>
        );
      },
    },
  ];

  const tableButton = {
    text: "Add Report",
    path: "reports/add",
  };
  const history = useHistory();

  const handleEdit = row => {
    history.push(`/panel/reports/add/${row.id}`);
  };
  return (
    <div className="page-content">
      <MetaTags>
        <title>Report List | Report</title>
      </MetaTags>
      <TableView
        tableName="Report List List"
        columns={columns}
        products={products}
        filterData={{ filter, handelFilter }}
        Pagination={serverPagination}
        sortDate={{ form: filter.from, to: filter.to }}
        editAction={auth.allowEdit ? handleEdit : ""}
        buttonAdd={auth.allowCreate ? tableButton : ""}
        filterFields={filterFields}
      />
    </div>
  );
};

export default Loader(ReportList);
