import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import ProfileImg from "./ProfileImg";
import Loader from "components/Common/Loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import "yup-phone";
import * as Yup from "yup";
import Select from "react-select";

import {
  getCityListAction,
  getCountryListAction,
  getStateListAction,
  uploadProfileAction,
} from "store/actions";
import Breadcrumbs from "components/Common/Breadcrumb";
import { toast } from "react-toastify";

const getUserDetails = props => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj.userInfo;
  }
};

const Profile = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [countryId, setCountryId] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const initialValues = {
    fName: "",
    lName: "",
    mobileNo: "",
    remarks: "",
    address1: "",
    address2: "",
    countryId: "",
    stateId: "",
    cityId: "",
    pinCode: "",
  };
  const [values, setValues] = useState(initialValues);
  const validationSchema = Yup.object({
    fName: Yup.string().min(2).max(100).required("Please enter First name"),
    lName: Yup.string().min(2).max(100),
    mobileNo: Yup.string()
      .required("Please Enter mobile number")
      .phone("IN", false, "Invalid mobile"),
    address1: Yup.string().required("please Enter Address"),
    address2: Yup.string(),
    countryId: Yup.string().required("Please Select Country"),
    stateId: Yup.string().required("Please Select State"),
    cityId: Yup.string().required("Please Select City"),
    pinCode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, "Invalid pin code")
      .required("Please Enter pin code"),
  });

  useEffect(() => {
    setStateOption(stateId.filter(val => val.underId == values.countryId));
    setCityOption(cityId.filter(val => val.underId == values.stateId));
  }, [values, stateId, cityId]);

  useEffect(async () => {
    try {
      props.setLoading(true);
      let response1 = await getCountryListAction();
      let response2 = await getStateListAction();
      let response3 = await getCityListAction();
      if (response1.status === 1) {
        props.setLoading(false);
        let arr = response1?.data || [];
        setCountryId(arr);
      } else {
        toast.error(response1.msg);
        props.setLoading(false);
      }
      if (response2.status === 1) {
        props.setLoading(false);
        let arr = response2?.data || [];
        setStateId(arr);
      } else {
        toast.error(response2.msg);
        props.setLoading(false);
      }
      if (response3.status === 1) {
        props.setLoading(false);
        let arr = response3?.data || [];
        setCityId(arr);
      } else {
        toast.error(response3.msg);
        props.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  useEffect(async () => {
    let profile = localStorage.getItem("profile");
    profile = profile ? JSON.parse(profile) : {};
    if (profile) {
      setValues({
        id: profile.id,
        email: profile.email,
        fName: profile.fName,
        lName: profile.lName,
        mobileNo: profile.mobileNo,
        remarks: profile.remarks,
        address1: profile.address1,
        address2: profile.address2,
        countryId: profile.countryId,
        stateId: profile.stateId,
        cityId: profile.cityId,
        pinCode: profile.pinCode,
        profileImg: profile.profileImg,
        code: profile.code,
      });
      setProfile(profile.profileImg.includes("noimage.png"));
    }
  }, [localStorage.getItem("profile")]);

  const handleChange = (e, setValues, values) => {
    const fd = JSON.parse(JSON.stringify(values));
    let name = e.target?.name || "";
    let value = e.target?.value || "";
    fd[name] = value;
    if (name == "userType") {
      fd.partner = "";
    }
    if (name == "countryId") {
      fd.stateId = "";
      fd.cityId = "";
      setStateOption(stateId.filter(val => val.underId == value));
      setCityOption([]);
    }
    if (name == "stateId") {
      setCityOption(cityId.filter(val => val.underId == value));
      fd.cityId = "";
    }
    setValues(fd);
  };

  const handleProfileImg = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async values => {
    try {
      props.setLoading(true);
      let response = await uploadProfileAction(values);
      if (response.status === 1) {
        props.setLoading(false);
        toast.success(response.msg);
        localStorage.setItem("profile", JSON.stringify(response.data));
      } else {
        toast.error(response.msg);
        props.setLoading(false);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItem="Profile"
              path="/panel/dashboard"
            />
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex flex-column align-items-center">
                    <div className="me-3 w-100 h-auto d-flex flex-column align-items-center">
                      {profile ? (
                        <span
                          className="material-icons-round rounded-circle img-thumbnail"
                          style={{ fontSize: "10vw" }}
                        >
                          account_circle
                        </span>
                      ) : (
                        <img
                          src={values.profileImg}
                          alt="profile"
                          className="avatar-xl rounded-circle img-thumbnail"
                        />
                      )}

                      <div
                        className="my-2 text-primary btn"
                        onClick={handleProfileImg}
                      >
                        <span className="material-icons-outlined fs-5">
                          edit
                        </span>{" "}
                        <span className="fs-5">Edit profile</span>
                      </div>
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>
                          <b>Name : </b> {values.fName} {values.lName}
                        </h5>
                        <p className="mb-1">
                          <b>Email : </b>
                          {values.email}
                        </p>
                        <p className="mb-0">
                          <b>User Code : </b> #{values.code}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ProfileImg
                    Open={isOpen}
                    handleClose={setIsOpen}
                    setLoading={props.setLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Formik
                    initialValues={values}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, values, setValues }) => (
                      <Form>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="fName">
                                First Name{" "}
                                <span className=" text-danger">*</span>
                              </Label>
                              <Field
                                type="text"
                                name="fName"
                                id="fName"
                                placeholder="Enter first Name"
                                maxLength={15}
                                className={
                                  "form-control" +
                                  (errors.fName && touched.fName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="LastName">Last Name</Label>
                              <Field
                                type="text"
                                name="lName"
                                id="LastName"
                                placeholder="Enter last Name"
                                maxLength={15}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="mobileNo">
                                Mobile Number{" "}
                                <span className=" text-danger">*</span>
                              </Label>
                              <Field
                                type="text"
                                name="mobileNo"
                                id="mobileNo"
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                placeholder="Enter Mobile Number"
                                maxLength={10}
                                className={
                                  "form-control" +
                                  (errors.mobileNo && touched.mobileNo
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="mobileNo"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="my-3">
                          <fieldset className="border border-3">
                            <legend className="float-none w-auto px-2">
                              Address
                            </legend>
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label for="address1">
                                    Address line 1{" "}
                                    <span className=" text-danger">*</span>
                                  </Label>
                                  <Field
                                    type="text"
                                    name="address1"
                                    id="address1"
                                    placeholder="Enter Address 1"
                                    maxLength={100}
                                    className={"form-control"}
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name="address1"
                                    />
                                  </span>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label for="address2">Address line 2</Label>
                                  <Field
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    placeholder="Enter Address 2"
                                    maxLength={100}
                                    className={"form-control"}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label for="countryId">
                                    Country
                                    <span className=" text-danger"> *</span>
                                  </Label>
                                  <Select
                                    name="countryId"
                                    id="countryId"
                                    placeholder="Enter Country Name"
                                    value={
                                      values.id &&
                                      countryId.filter(
                                        obj => obj.id === values.countryId
                                      )[0]
                                    }
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e.id}
                                    onChange={e =>
                                      handleChange(
                                        {
                                          target: {
                                            name: "countryId",
                                            value: e.id,
                                          },
                                        },
                                        setValues,
                                        values
                                      )
                                    }
                                    options={countryId}
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name="countryId"
                                    />
                                  </span>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label for="stateId">
                                    State{" "}
                                    <span className=" text-danger">*</span>
                                  </Label>

                                  <Select
                                    name="stateId"
                                    placeholder="Enter State Name"
                                    value={stateId.find(
                                      obj => obj.id === values.stateId
                                    )}
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e.id}
                                    onChange={e =>
                                      handleChange(
                                        {
                                          target: {
                                            name: "stateId",
                                            value: e.id,
                                          },
                                        },
                                        setValues,
                                        values
                                      )
                                    }
                                    options={stateOption}
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name="stateId"
                                    />
                                  </span>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label for="cityId">
                                    City <span className=" text-danger">*</span>
                                  </Label>
                                  <Select
                                    name="cityId"
                                    id="cityId"
                                    value={cityId.find(
                                      obj => obj.id === values.cityId
                                    )}
                                    placeholder="Enter City"
                                    getOptionLabel={e => e.name}
                                    getOptionValue={e => e.id}
                                    onChange={e =>
                                      handleChange(
                                        {
                                          target: {
                                            name: "cityId",
                                            value: e.id,
                                          },
                                        },
                                        setValues,
                                        values
                                      )
                                    }
                                    options={cityOption}
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name="cityId"
                                    />
                                  </span>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label for="pinCode">
                                    Pin Code{" "}
                                    <span className=" text-danger">*</span>
                                  </Label>
                                  <Field
                                    type="text"
                                    name="pinCode"
                                    id="pinCode"
                                    placeholder="Enter Pin Code"
                                    maxLength={15}
                                    className="form-control"
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name="pinCode"
                                    />
                                  </span>
                                </FormGroup>
                              </Col>
                            </Row>
                          </fieldset>
                        </Row>

                        <Row>
                          <Col xs={{ size: 2, offset: 5 }}>
                            <Button color="primary" type="submit">
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Loader(Profile);
