import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import RadialChart from "./RadialChart";
import PieChart from "./PieChart";
import DonutChart from "./DountChart";
import PolarChart from "./polarchart";
import { getChartsData } from "store/actions";
import Loader from "components/Common/Loader";

const Index = props => {
  const [record, setRecord] = useState([]);
  useEffect(async () => {
    try {
      props.setLoading(true);
      const response = await getChartsData();
      if (response.status === 1) {
        let obj = response?.data || {};
        console.log(obj);
        setRecord(obj);
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, []);
  return (
    <div className="page-content">
      <MetaTags>
        <title>All Charts</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Charts" breadcrumbItem="All Charts" />

        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Monthly Sales Pie Chart </CardTitle>
                <PieChart />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Monthly Sales Donut Chart
                </CardTitle>
                <DonutChart />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Monthly Sales Polar Chart
                </CardTitle>
                {/* <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">4852</h5>
                      <p className="text-muted">Activated</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">3652</h5>
                      <p className="text-muted">Pending</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">85412</h5>
                      <p className="text-muted">Deactivated</p>
                    </div>
                  </Col>
                </Row> */}
                <PolarChart />
              </CardBody>
            </Card>
          </Col>

          {/* <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Radar Chart</CardTitle>
                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">694</h5>
                      <p className="text-muted">Activated</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">55210</h5>
                      <p className="text-muted">Pending</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">489498</h5>
                      <p className="text-muted">Deactivated</p>
                    </div>
                  </Col>
                </Row>

                <RadarChart />
              </CardBody>
            </Card>
          </Col> */}
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Monthly Sales Radial Chart
                </CardTitle>
                <RadialChart />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Radial Chart</CardTitle>
                <RadialChart />
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default Loader(Index);
