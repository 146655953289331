import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Alert,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { MdLockReset, MdOutlineRemoveRedEye } from "react-icons/md";
import filterFactory from "react-bootstrap-table2-filter";

function formatDate() {
  let d = new Date();
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const TableView = props => {
  const labelWrap = {
    whiteSpace: "nowrap",
    overFlow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  };
  const filter = props.filterData?.filter || "";
  const handelFilter = props.filterData?.handelFilter || "";
  const [columns, setColumns] = useState([]);
  const [filterFieldTimerId, setFilterFieldTimerId] = useState(null);
  const [defaultSorted, setDefaultSorted] = useState([
    {
      dataField: "id",
      order: "asc",
    },
  ]);
  const [products, setProducts] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (props.columns) {
      if (
        props.editAction ||
        props.editRowAction ||
        props.resetPasswordAction ||
        props.viewAction ||
        props.deleteAction ||
        props.deleteRowAction
      ) {
        setColumns([
          ...props.columns,
          {
            dataField: "action",
            text: "Action",
            formatter: (data, row) => {
              return (
                <div className="button-items">
                  {props.editAction && (
                    <Button
                      color="primary"
                      onClick={() => props.editAction(row)}
                    >
                      <AiOutlineEdit />
                    </Button>
                  )}
                  {props.editRowAction ? (
                    <Button
                      color="primary"
                      onClick={() => props.editRowAction(row)}
                    >
                      <AiOutlineEdit />
                    </Button>
                  ) : (
                    ""
                  )}
                  {props.resetPasswordAction && (
                    <Button
                      color="primary"
                      onClick={() => props.resetPasswordAction(row)}
                    >
                      <MdLockReset />
                    </Button>
                  )}
                  {props.viewAction && (
                    <Button
                      color="primary"
                      onClick={() => props.viewAction(row)}
                    >
                      <MdOutlineRemoveRedEye />
                    </Button>
                  )}
                  {props.deleteAction && (
                    <Button
                      color="danger"
                      onClick={() => props.deleteAction(row)}
                    >
                      <AiOutlineDelete />
                    </Button>
                  )}
                  {props.deleteRowAction && row.isLive == "0" ? (
                    <Button
                      color="danger"
                      onClick={() => props.deleteRowAction(row)}
                    >
                      <AiOutlineDelete />
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
        ]);
      } else {
        setColumns(props.columns);
      }
    }
    if (props.defaultSorted) {
      setDefaultSorted(props.defaultSorted);
    }
    if (props.products) {
      setProducts(props.products);
    }
  }, [props]);

  const pageOptions = {
    custom: true,
    page: props.Pagination.currentPage || filter.pagination.page,
    sizePerPage: props.Pagination.itemsPerPage || filter.pagination.sizePerPage,
    nextPageText: "Next",
    totalSize: props.Pagination.totalItems || products.length,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: props.Pagination.totalItems || products.length },
    ],
  };
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    const pageNumber = type === "pagination" ? page : 1;
    let searchText = {};
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const data = filters[key];
        searchText = { ...searchText, [key]: data.filterVal };
      }
    }

    handelFilter({
      ...filter,
      pagination: { page: pageNumber, sizePerPage: sizePerPage },
      searchText: searchText,
      sortField: sortField ? sortField : filter.sortField,
      sortOrder: sortOrder,
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    handelFilter({
      ...filter,
      [name]: value,
    });
  };
  const handelFilterField = e => {
    let prevFilter = JSON.parse(JSON.stringify(filter));
    const { name, value, tagName } = e.target;
    (prevFilter.pagination = { ...prevFilter.pagination, page: 1 }),
      (prevFilter.searchText = { ...prevFilter.searchText, [name]: value });
    if (tagName == "INPUT") {
      clearTimeout(filterFieldTimerId); // Cancel previous invocation
      const newTimerId = setTimeout(() => {
        handelFilter(prevFilter);
      }, 1000);
      setFilterFieldTimerId(newTimerId);
    } else {
      handelFilter(prevFilter);
    }
  };
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            {columns.length > 0 && (
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={products}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          {props.tableName && (
                            <Col sm="6" md="9">
                              <div className="h2">{props.tableName}</div>
                            </Col>
                          )}
                          <Col
                            sm="6"
                            md="3"
                            className="d-flex align-items-center justify-content-end"
                          >
                            {props.buttonAdd && (
                              <Button
                                className="btn btn-primary btn-lg"
                                onClick={() =>
                                  history.push(`/panel/${props.buttonAdd.path}`)
                                }
                              >
                                <AiOutlinePlus />
                                {props.buttonAdd.text}
                              </Button>
                            )}
                            {props.exportAction && (
                              <Button
                                className="btn btn-primary d-flex btn-lg"
                                onClick={props.exportAction}
                              >
                                <span className="material-icons-outlined me-1">
                                  file_download
                                </span>
                                <span className="fs-6 fw-bold">
                                  Export Data
                                </span>
                              </Button>
                            )}
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          {props.search && (
                            <Col sm="6" md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <Input
                                    type="text"
                                    placeholder="search"
                                    name="search"
                                    onChange={e => {
                                      props.search(
                                        e.target.name,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          )}
                          {props.sortDate && (
                            <Col sm="6" md="4">
                              <div className="row py-1">
                                <div className="col-3 mt-auto mb-auto pe-0">
                                  <label
                                    htmlFor="fromDate"
                                    className="mb-0 me-2 fs-6 fw-bold"
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    From Date
                                  </label>
                                </div>
                                <div className="col-9">
                                  <input
                                    className=" form-control"
                                    id="fromDate"
                                    name="from"
                                    placeholder="date placeholder"
                                    type="date"
                                    max={formatDate()}
                                    value={filter.from}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                          {props.sortDate && (
                            <Col sm="6" md="4">
                              <div className="row py-1">
                                <div className="col-3 mt-auto mb-auto pe-0">
                                  <Label
                                    for="toDate"
                                    className="mb-0 me-2 fs-6 fw-bold"
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    To Date
                                  </Label>
                                </div>
                                <div className="col-9">
                                  <Input
                                    id="toDate"
                                    name="to"
                                    placeholder="date placeholder"
                                    type="date"
                                    max={formatDate()}
                                    value={filter.to}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                          {props.partner && (
                            <Col sm="6" md="4">
                              <div className="row py-1">
                                <div className="col-3 mt-auto mb-auto pe-0">
                                  <Label
                                    for="partner"
                                    className="mb-0 me-2 fs-6 fw-bold"
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Partner
                                  </Label>
                                </div>
                                <div className="col-9">
                                  <select
                                    name="partner"
                                    id="partner"
                                    className="form-select"
                                    value={props.partner.defaultPartner}
                                    onChange={e =>
                                      props.partner.handleChange(e.target.value)
                                    }
                                  >
                                    {props.partner.option.map(obj => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj.companyName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </Col>
                          )}
                          {props.filterFields &&
                            props.filterFields.map((field, index) => (
                              <Col sm="6" md="4" key={index}>
                                {field.filter ? (
                                  <div className="row py-1">
                                    <div className="col-3 mt-auto mb-auto pe-0">
                                      <Label
                                        for={field.dataField}
                                        className="mb-0 me-2 fs-6 fw-bold"
                                        style={labelWrap}
                                      >
                                        {field.text}
                                      </Label>
                                    </div>
                                    <div className="col-9">
                                      <select
                                        name={field.dataField}
                                        id={field.dataField}
                                        className="form-select"
                                        value={
                                          filter.searchText[field.dataField] ||
                                          field.defaultValue
                                        }
                                        onChange={handelFilterField}
                                      >
                                        {field.filter.map(obj => (
                                          <option
                                            key={obj.label}
                                            value={obj.value}
                                          >
                                            {obj.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row py-1">
                                    <div className="col-3 mt-auto mb-auto pe-0">
                                      <Label
                                        for={field.dataField}
                                        className="mb-0 me-2 fs-6 fw-bold"
                                        style={labelWrap}
                                      >
                                        {field.text}
                                      </Label>
                                    </div>
                                    <div className="col-9">
                                      <Input
                                        id={field.dataField}
                                        name={field.dataField}
                                        placeholder={`${
                                          field.placeholder || field.text
                                        }`}
                                        type="text"
                                        onChange={handelFilterField}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Col>
                            ))}

                          {props.filterComponent && props.filterComponent()}
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                remote
                                keyField="id"
                                responsive
                                hover
                                condensed
                                bordered
                                striped={false}
                                filter={filterFactory()}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                onTableChange={handleTableChange}
                                noDataIndication={
                                  <Alert className="text-center" color="danger">
                                    No Data Found
                                  </Alert>
                                }
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-3">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationTotalStandalone {...paginationProps} />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TableView;
