import React from "react";
import ReactApexChart from "react-apexcharts";

const Apaexlinecolumn = props => {
  const series = props.data.series;

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: props.data.labels,
      title: { text: "Brands" },
    },
    yaxis: {
      title: {
        text: "Quantity",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={410}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default Apaexlinecolumn;
