import React from "react";
import ReactEcharts from "echarts-for-react";

const LineBar = props => {
  const options = {
    grid: {
      zlevel: 0,
      x: 80,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(0,0,0,0)",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1b44c", "#50a5f1"],
    legend: {
      data: ["Qty", "Amount"],
      textStyle: {
        color: ["#8791af"],
      },
    },
    xAxis: [
      {
        type: "category",
        data: props.data.labels,
        axisPointer: {
          type: "shadow",
        },
        axisLine: {
          lineStyle: {
            color: "#8791af",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "Quantity",
        min: Math.min(...props.data.series[0].data),
        max: Math.max(...props.data.series[0].data),
        interval: 500,
        axisLine: {
          lineStyle: {
            color: "#8791af",
          },
        },
      },
      props.data.series[1]
        ? {
            type: "value",
            name: "Amount",
            min: Math.min(...props.data.series[1].data),
            max: Math.max(...props.data.series[1].data),
            interval: 1000000,
            axisLabel: {
              formatter: value => {
                return value.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                });
              },
            },
            axisLine: {
              lineStyle: {
                color: "#8791af",
              },
            },
          }
        : {},
    ],
    series: [
      {
        type: "bar",
        name: "Qty",
        data: props.data.series[0] && props.data.series[0].data,
      },
      {
        type: "line",
        name: "Amount",
        yAxisIndex: 1,
        data: props.data.series[1] && props.data.series[1].data,
      },
    ],
    textStyle: {
      color: ["#74788d"],
    },
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};

export default LineBar;
