import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import Loader from "components/Common/Loader";
import {
  getCityListAction,
  getCountryListAction,
  getPartnerAction,
  getStateListAction,
  savePartnerAction,
} from "store/actions";
import Select from "react-select";
import { getFormId, urlValidation } from "helpers/common_helper";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";

const PartnerAdd = props => {
  const { id } = useParams();
  urlValidation(getFormId());
  const initialValues = {
    companyName: "",
    printName: "",
    gstNumber: "",
    maxVender: "10",
    maxUser: "3",
    status: "1",
    address1: "",
    address2: "",
    countryId: "",
    stateId: "",
    cityId: "",
    pinCode: "",
    logo: "",
    cpInfo: [
      {
        name: "",
        designation: "",
        email: "",
        mobileNumber: "",
      },
    ],
  };
  const [formData, setFormData] = useState(initialValues);
  const [countryId, setCountryId] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const history = useHistory();

  useEffect(async () => {
    try {
      props.setLoading(true);
      let response1 = await getCountryListAction();
      let response2 = await getStateListAction();
      let response3 = await getCityListAction();
      if (response1.status === 1) {
        props.setLoading(false);
        let arr = response1?.data || [];
        setCountryId(arr);
      } else {
        toast.error(response1.msg);
        props.setLoading(false);
      }
      if (response2.status === 1) {
        props.setLoading(false);
        let arr = response2?.data || [];
        setStateId(arr);
      } else {
        toast.error(response2.msg);
        props.setLoading(false);
      }
      if (response3.status === 1) {
        props.setLoading(false);
        let arr = response3?.data || [];
        setCityId(arr);
      } else {
        toast.error(response3.msg);
        props.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  useEffect(async () => {
    try {
      if (id) {
        props.setLoading(true);
        const response = await getPartnerAction(id);
        if (response.status === 1) {
          let {
            companyName,
            printName,
            gstNumber,
            maxVender,
            maxUser,
            status,
            address1,
            address2,
            countryId,
            stateId,
            cityId,
            pinCode,
            cpInfo,
          } = response.data;
          const initialValues = {
            companyName: companyName,
            printName: printName,
            gstNumber: gstNumber,
            maxVender: maxVender,
            maxUser: maxUser,
            status: `${status}`,
            address1: address1,
            address2: address2,
            countryId: countryId,
            stateId: stateId,
            cityId: cityId,
            pinCode: pinCode,
            cpInfo: cpInfo.map(val => ({
              id: val.id,
              name: val.name,
              designation: val.designation,
              email: val.email,
              mobileNumber: val.mobileNo,
            })),
          };
          setFormData(initialValues);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setStateOption(stateId.filter(val => val.underId == formData.countryId));
      setCityOption(cityId.filter(val => val.underId == formData.stateId));
    }
  }, [formData, stateId, cityId]);

  const handleSubmit = async (values, actions) => {
    try {
      props.setLoading(true);
      let response = await savePartnerAction(
        {
          ...values,
          formId: getFormId(),
        },
        id
      );
      if (response.status === 1) {
        props.setLoading(false);
        actions.resetForm();
        if (id) {
          history.push("/panel/partner/list");
        }
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  const handleChange = (e, setValues, values) => {
    const fd = JSON.parse(JSON.stringify(values));
    let name = e.target?.name || "";
    let value = e.target?.value || "";
    fd[name] = value;
    if (name == "countryId") {
      fd.stateId = "";
      fd.cityId = "";
      setStateOption(stateId.filter(val => val.underId == value));
      setCityOption([]);
    }
    if (name == "stateId") {
      setCityOption(cityId.filter(val => val.underId == value));
      fd.cityId = "";
    }
    setValues(fd);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{7,8}?$/;
  Yup.addMethod(Yup.array, "unique", function (field, message) {
    return this.test("unique", message, function (array) {
      const uniqueData = Array.from(
        new Set(array.map(row => row[field]?.toLowerCase()))
      );
      const isUnique = array.length === uniqueData.length;
      if (isUnique) {
        return true;
      }
      const index = array.findIndex(
        (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
      );
      if (array[index][field] === "") {
        return true;
      }
      return this.createError({
        path: `${this.path}.${index}.${field}`,
        message,
      });
    });
  });
  const validationSchema = Yup.object({
    companyName: Yup.string()
      .min(3, "Company Name should be 3-50 only characters")
      .max(50, "Company Name should be 3-50 only characters")
      .matches(
        /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/g,
        "Invalid Company Name"
      )
      .required("Please enter Company Name"),
    printName: Yup.string()
      .min(3, "Company Name should be 3-50 only characters")
      .max(50, "Company Name should be 3-50 only characters")
      .matches(
        /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/g,
        "Invalid Print Name"
      )
      .required("Please enter Print Name"),
    gstNumber: Yup.string(),
    maxVender: Yup.number()
      .min(1, "Please enter minimum 1 Vender")
      .required("Please Enter number of Vender"),
    maxUser: Yup.number()
      .min(1, "Please enter minimum 1 Vender")
      .required("Please Enter number of Vender"),
    logo: Yup.string(),
    status: Yup.string().required("Select partner status"),
    address1: Yup.string().required("please Enter Address"),
    address2: Yup.string(),
    countryId: Yup.string().required("Please Select Country"),
    stateId: Yup.string().required("Please Select State"),
    cityId: Yup.string().required("Please Select City"),
    pinCode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, "Invalid pin code")
      .required("Please Enter pin code"),
    cpInfo: Yup.array()
      .unique("email", "Please provide a unique email.")
      .unique("mobileNumber", "Please provide a unique mobile number.")
      .of(
        Yup.object().shape({
          name: Yup.string().required("Name required"),
          email: Yup.string()
            .required("email required")
            .email("Enter valid email"),
          designation: Yup.string().required("Please enter designation"),
          mobileNumber: Yup.string()
            .matches(phoneRegExp, "Invalid mobile number")
            .required("Please enter mobile number"),
        })
      ),
  });

  return (
    <div className="page-content mb-4">
      <Container>
        {id && (
          <Breadcrumbs
            title="Partners"
            breadcrumbItem={"Edit Partner"}
            path="/panel/partner/list"
          />
        )}
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(val, acc) => handleSubmit(val, acc)}
                >
                  {({ errors, touched, resetForm, values, setValues }) => (
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="companyName">
                              Company Name{" "}
                              <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="companyName"
                              id="companyName"
                              placeholder="Enter Company Name"
                              maxLength={50}
                              className={"form-control"}
                            />

                            <span className="text-danger">
                              <ErrorMessage name="companyName" />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="printName">
                              Print Name <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="printName"
                              id="printName"
                              placeholder="Enter Company print Name"
                              maxLength={50}
                              className={"form-control"}
                            />
                            <span className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name="printName"
                              />
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="gstNumber">GST No.</Label>
                            <Field
                              type="text"
                              name="gstNumber"
                              id="gstNumber"
                              placeholder="Enter GST Number"
                              maxLength={50}
                              className={"form-control"}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="maxVender">
                              Max Vender Allow
                              <span className=" text-danger"> *</span>
                            </Label>
                            <Field
                              type="number"
                              name="maxVender"
                              id="maxVender"
                              placeholder="Enter max Vender"
                              className={"form-control"}
                            />
                            <span className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name="maxVender"
                              />
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="maxUser">
                              Max. Admin User
                              <span className=" text-danger"> *</span>
                            </Label>
                            <Field
                              type="number"
                              name="maxUser"
                              id="maxUser"
                              placeholder="Enter max User"
                              className="form-control"
                            />
                            <span className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name="maxUser"
                              />
                            </span>
                          </FormGroup>
                        </Col>
                        {/* <Col md="6">
                          <FormGroup>
                            <Label for="logo">
                              Company logo
                              <span className=" text-danger"> *</span>
                            </Label>
                            <Field
                              type="file"
                              name="logo"
                              id="logo"
                              placeholder="Enter max User"
                              className="form-control"
                            />
                            <span className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name="logo"
                              />
                            </span>
                          </FormGroup>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="mt-4">
                            <h5 className="font-size-14 mb-1">
                              Status <span className=" text-danger">*</span>
                            </h5>
                            <div className="d-flex">
                              <div className="form-check me-2">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="active"
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="active"
                                >
                                  active
                                </label>
                              </div>
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inactive"
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inactive"
                                >
                                  inactive
                                </label>
                              </div>
                            </div>
                          </div>
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="status"
                            />
                          </span>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <fieldset className="border border-3">
                          <legend className="float-none w-auto px-2">
                            Address
                          </legend>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for="address1">
                                  Address line 1{" "}
                                  <span className=" text-danger">*</span>
                                </Label>
                                <Field
                                  type="text"
                                  name="address1"
                                  id="address1"
                                  placeholder="Enter Address 1"
                                  maxLength={100}
                                  className={"form-control"}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="address1"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="address2">Address line 2</Label>
                                <Field
                                  type="text"
                                  name="address2"
                                  id="address2"
                                  placeholder="Enter Address 2"
                                  maxLength={100}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for="countryId">
                                  Country
                                  <span className=" text-danger"> *</span>
                                </Label>
                                <Select
                                  name="countryId"
                                  id="countryId"
                                  placeholder="Enter Country Name"
                                  value={
                                    id &&
                                    countryId.filter(
                                      obj => obj.id === formData.countryId
                                    )[0]
                                  }
                                  getOptionLabel={e => e.name}
                                  getOptionValue={e => e.id}
                                  onChange={e =>
                                    handleChange(
                                      {
                                        target: {
                                          name: "countryId",
                                          value: e.id,
                                        },
                                      },
                                      setValues,
                                      values
                                    )
                                  }
                                  options={countryId}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="countryId"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="stateId">
                                  State <span className=" text-danger">*</span>
                                </Label>

                                <Select
                                  name="stateId"
                                  placeholder="Enter State Name"
                                  value={
                                    id &&
                                    stateId.find(
                                      obj => obj.id === formData.stateId
                                    )
                                  }
                                  getOptionLabel={e => e.name}
                                  getOptionValue={e => e.id}
                                  onChange={e =>
                                    handleChange(
                                      {
                                        target: {
                                          name: "stateId",
                                          value: e.id,
                                        },
                                      },
                                      setValues,
                                      values
                                    )
                                  }
                                  options={stateOption}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="stateId"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for="cityId">
                                  City <span className=" text-danger">*</span>
                                </Label>
                                <Select
                                  name="cityId"
                                  id="cityId"
                                  value={
                                    id &&
                                    cityId.find(
                                      obj => obj.id === formData.cityId
                                    )
                                  }
                                  placeholder="Enter City"
                                  getOptionLabel={e => e.name}
                                  getOptionValue={e => e.id}
                                  onChange={e =>
                                    handleChange(
                                      {
                                        target: {
                                          name: "cityId",
                                          value: e.id,
                                        },
                                      },
                                      setValues,
                                      values
                                    )
                                  }
                                  options={cityOption}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="cityId"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="pinCode">
                                  Pin Code{" "}
                                  <span className=" text-danger">*</span>
                                </Label>
                                <Field
                                  type="text"
                                  name="pinCode"
                                  id="pinCode"
                                  placeholder="Enter Pin Code"
                                  maxLength={15}
                                  className="form-control"
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="pinCode"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>
                        </fieldset>
                      </Row>
                      <FieldArray
                        name="cpInfo"
                        render={arrayHelpers => {
                          const cpInfo = values.cpInfo;

                          return (
                            <div>
                              {cpInfo && cpInfo.length > 0
                                ? cpInfo.map((cpInfo, i) => (
                                    <Row className="my-3" key={i}>
                                      <fieldset className="border border-3">
                                        <legend className="float-none w-auto px-2">
                                          Contact person {i + 1}
                                        </legend>
                                        <Row>
                                          <Col md="6">
                                            <FormGroup>
                                              <Label for="name">
                                                Name{" "}
                                                <span className=" text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                type="text"
                                                name={`cpInfo.${i}.name`}
                                                id="name"
                                                placeholder="Enter name"
                                                maxLength={100}
                                                className={"form-control"}
                                              />
                                              <span className="text-danger">
                                                <ErrorMessage
                                                  className="text-danger"
                                                  name={`cpInfo.${i}.name`}
                                                />
                                              </span>
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup>
                                              <Label for="designation">
                                                Designation{" "}
                                                <span className=" text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                type="text"
                                                name={`cpInfo.${i}.designation`}
                                                id="designation"
                                                placeholder="Enter designation"
                                                maxLength={100}
                                                className={"form-control"}
                                              />
                                              <span className="text-danger">
                                                <ErrorMessage
                                                  className="text-danger"
                                                  name={`cpInfo.${i}.designation`}
                                                />
                                              </span>
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup>
                                              <Label for="email">
                                                Email{" "}
                                                <span className=" text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                type="email"
                                                name={`cpInfo.${i}.email`}
                                                id="email"
                                                placeholder="Enter email"
                                                maxLength={100}
                                                className={"form-control"}
                                              />
                                              <span className="text-danger">
                                                <ErrorMessage
                                                  className="text-danger"
                                                  name={`cpInfo.${i}.email`}
                                                />
                                              </span>
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup>
                                              <Label for="mobileNumber">
                                                Mobile No.{" "}
                                                <span className=" text-danger">
                                                  *
                                                </span>
                                              </Label>
                                              <Field
                                                type="text"
                                                name={`cpInfo.${i}.mobileNumber`}
                                                id="mobileNumber"
                                                placeholder="Enter Mobile Number"
                                                maxLength={100}
                                                className={"form-control"}
                                                onKeyPress={event => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                              />
                                              <span className="text-danger">
                                                <ErrorMessage
                                                  className="text-danger"
                                                  name={`cpInfo.${i}.mobileNumber`}
                                                />
                                              </span>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <div className="d-flex mb-3">
                                          <button
                                            className={`btn btn-danger ms-auto ${
                                              cpInfo.length == 1
                                                ? "disabled"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              arrayHelpers.remove(i)
                                            }
                                            type="button"
                                          >
                                            Remove CP
                                          </button>
                                        </div>
                                      </fieldset>
                                    </Row>
                                  ))
                                : null}
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  arrayHelpers.push({
                                    name: "",
                                    designation: "",
                                    email: "",
                                    mobileNumber: "",
                                  })
                                }
                                type="button"
                              >
                                Add CP
                              </button>
                            </div>
                          );
                        }}
                      />

                      <Row>
                        <Col xs={{ size: 2, offset: 5 }}>
                          <Button color="primary">Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loader(PartnerAdd);
