import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

import { connect } from "react-redux";
import { getProfileAction } from "store/actions";
import { toast } from "react-toastify";

const getUserDetails = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj.userInfo;
  }
};

const ProfileMenu = props => {
  const [userDetails, setUserDetails] = useState({
    menu: false,
    user: "",
  });
  const [profile, setProfile] = useState(false);
  const handelToggle = () => {
    setUserDetails(prevState => ({
      ...prevState,
      menu: !prevState.menu,
    }));
  };

  useEffect(async () => {
    let profile = localStorage.getItem("profile");
    profile = profile ? JSON.parse(profile) : {};
    if (profile) {
      setUserDetails(prevState => ({
        ...prevState,
        user: profile,
      }));
      setProfile(profile.profileImg.includes("noimage.png"));
    }
  }, [localStorage.getItem("profile")]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={userDetails.menu}
        toggle={handelToggle}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          {profile ? (
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />
          ) : (
            <img
              className="rounded-circle header-profile-user"
              src={userDetails.user.profileImg}
              alt="Header Avatar"
            />
          )}
          <span className="d-none d-xl-inline-block ms-1">
            {userDetails.user.fName} {userDetails.user.lName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/panel/user/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle ms-1" />
            {props.t("Profile")}
          </Link>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
              <i className="bx bx-wallet font-size-16 align-middle me-1" />
              {this.props.t("My Wallet")}
            </DropdownItem> */}

          <Link to="/panel/user/changepassword" className="dropdown-item">
            {/* <span className="badge bg-success float-end">11</span> */}
            <i className="bx bx-wrench font-size-17 align-middle me-1" />
            Settings
          </Link>

          {/* <DropdownItem tag="a" href="auth-lock-screen">
              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
              {this.props.t("Lock screen")}
            </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
};

const mapStateToProps = state => {
  const { success } = state.Profile;
  return { success };
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
);
