import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useParams, useHistory } from "react-router-dom";
import { getFormId } from "helpers/common_helper";
import { getUgListAction, saveUserGroupAction } from "store/actions";
import { toast } from "react-toastify";
import Loader from "components/Common/Loader";
const GroupAdd = props => {
  const { id } = useParams();
  const history = useHistory();
  const initialValues = {
    name: "",
    status: "1",
    remarks: "",
    userType: "Admin",
  };
  const [values, setValues] = useState(initialValues);
  const validationSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter group name"),
  });
  const handleSubmit = async (values, action) => {
    try {
      props.setLoading(true);
      let response = await saveUserGroupAction(
        { ...values, formId: getFormId() },
        id
      );
      if (response.status === 1) {
        props.setLoading(false);
        toast.success(response.msg);
        if (id) {
          history.push("/panel/usergroup/list");
        }
        action.resetForm();
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  useEffect(async () => {
    try {
      if (id) {
        props.setLoading(true);
        const response = await getUgListAction(id);
        if (response.status === 1) {
          setValues({
            name: response.data.name,
            status: `${response.data.status}`,
            remarks: response.data.remarks,
            userType: response.data.userType,
          });
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [id]);
  return (
    <div className="page-content mb-4">
      <Container>
        {id && (
          <Breadcrumbs
            title="User Group"
            breadcrumbItem={"Edit User Group"}
            path="/panel/usergroup/list"
          />
        )}

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={values}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(val, acc) => handleSubmit(val, acc)}
                >
                  {({ errors, touched, resetForm }) => (
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="name">
                              Group name <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Enter Group Name"
                              maxLength={100}
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <Col md="6">
                            <div className="mt-4">
                              <h5 className="font-size-14 mb-1">
                                User type
                                <span className=" text-danger"> *</span>
                              </h5>
                              <div className="d-flex">
                                <div className="form-check me-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="userType"
                                    id="admin"
                                    value="Admin"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="admin"
                                  >
                                    Admin
                                  </label>
                                </div>
                                <div className="form-check me-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="userType"
                                    id="Partner"
                                    value="Partner-User"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Partner"
                                  >
                                    Partner user
                                  </label>
                                </div>
                                <div className="form-check">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="userType"
                                    id="Vender"
                                    value="Vender"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Vender"
                                  >
                                    Vender
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Col>
                        <Row>
                          <Col md="6" className="my-4">
                            <div className="">
                              <h5 className="font-size-14 mb-1">
                                Status <span className=" text-danger">*</span>
                              </h5>
                              <div className="d-flex">
                                <div className="form-check me-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="active"
                                    value="1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="active"
                                  >
                                    active
                                  </label>
                                </div>
                                <div className="form-check">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="inactive"
                                    value="0"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inactive"
                                  >
                                    inactive
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="remarks">Remark</Label>
                            <Field
                              as="textarea"
                              name="remarks"
                              placeholder="Remark"
                              id="remarks"
                              rows="5"
                              maxLength={300}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={{ size: 2, offset: 5 }}>
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loader(GroupAdd);
