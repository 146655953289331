import Breadcrumbs from "components/Common/Breadcrumb";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Chartapex from "./chartapex";
import StackBarChart from "./stackedbarchart";
import SplineArea from "./SplineArea";

import "chartist/dist/scss/chartist.scss";
import "assets/scss/chartist.scss";
import LineBar from "./linebarchart";
import Apaexlinecolumn from "./apaexlinecolumn";
const Index = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>All Graph</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Graph" breadcrumbItem="All Graph" />

        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Monthly Sales</CardTitle>
                {/* <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">86541</h5>
                      <p className="text-muted">Activated</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">2541</h5>
                      <p className="text-muted">Pending</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">102030</h5>
                      <p className="text-muted">Deactivated</p>
                    </div>
                  </Col>
                </Row> */}
                <Apaexlinecolumn />
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Monthly Sales Stacked bar chart
                </CardTitle>
                {/*
                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">5241</h5>
                      <p className="text-muted">Activated</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">65411</h5>
                      <p className="text-muted">Pending</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">51654</h5>
                      <p className="text-muted">Deactivated</p>
                    </div>
                  </Col>
                </Row> */}
                <Chartapex />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Monthly Sales Bar Diagram
                </CardTitle>
                {/* <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">5677</h5>
                      <p className="text-muted">Activated</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">5542</h5>
                      <p className="text-muted">Pending</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="text-center">
                      <h5 className="mb-0 font-size-20">12422</h5>
                      <p className="text-muted">Deactivated</p>
                    </div>
                  </Col>
                </Row> */}

                <SplineArea />
              </CardBody>
            </Card>
          </Col>

          <Col lg="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-4 h4">
                  Monthly Sales Mix Line-Bar
                </CardTitle>
                <div id="mix-line-bar" className="e-chart">
                  <LineBar />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Index;
