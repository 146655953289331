import { toast } from "react-toastify";
import ConfirmModel from "../../components/Common/ConfirmModel";
import TableView from "../../components/Common/TableView";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { delUserGroupAction, getUgListAction } from "store/actions";
import { decrypt, getFormId, urlValidation } from "helpers/common_helper";
import Loader from "components/Common/Loader";
import useFilter from "components/Common/useFilter";

const GroupList = props => {
  const service_group_id = process.env.REACT_APP_SERVICE_GROUP;
  const [groupId, setGroupId] = useState("");
  const [products, setProducts] = useState([]);
  const [confirmModelPre, setConfModelPro] = useState({
    modelMessage: "",
    confirmationState: false,
    modelOpen: false,
  });
  const formId = getFormId();
  const auth = urlValidation(formId);
  const [filter, handelFilter] = useFilter(6);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });

  useEffect(async () => {
    props.setLoading(true);
    try {
      const encodedQueryString = decrypt({
        ...filter,
        sortField: "createdAt",
        sortDirection: "DESC",
      });
      const response = await getUgListAction("", encodedQueryString);
      if (response.status === 1) {
        setServerPagination({ ...response.metadata });
        setProducts(response.data.filter(val => val.id !== service_group_id));
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [filter]);

  const tableButton = {
    text: "Add Group",
    path: "usergroup/add",
  };

  const history = useHistory();

  const handleEdit = row => {
    history.push(`/panel/usergroup/add/${row.id}`);
  };

  const handleDelete = async row => {
    const data = products.filter(val => val.id === row.id);
    setGroupId(row.id);
    setConfModelPro({
      ...confirmModelPre,
      modelMessage: `Are you sure you want to delete ${data.name}'s group`,
      modelOpen: true,
    });
  };

  const handleConfState = async stateValue => {
    try {
      setConfModelPro({
        ...confirmModelPre,
        confirmationState: stateValue,
        modelOpen: false,
      });
      if (stateValue) {
        props.setLoading(true);
        const response = await delUserGroupAction({ formId: formId }, groupId);
        if (response.status === 1) {
          props.setLoading(false);
          const data = products.filter(val => val.id !== groupId);
          setProducts(data);
          toast.success(response.msg);
          handelFilter({
            ...filter,
            pagination: {
              page: 1,
            },
          });
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  const handleClose = modelState => {
    setConfModelPro({ ...confirmModelPre, modelOpen: modelState });
  };
  const filterFields = [
    {
      dataField: "userType",
      text: "User Type",
      filter: [
        { value: "", label: "All" },
        { value: "Admin", label: "Admin" },
        { value: "Partner-User", label: "Partner User" },
        { value: "Vender", label: "Vender" },
      ],
      defaultValue: "",
    },
  ];
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "remarks",
      text: "Remark",
    },
    {
      dataField: "userType",
      text: "User Type",
    },
    {
      dataField: "createdAt",
      text: "Created",
      sort: true,
      formatter: data => {
        return data.slice(0, 10).split("-").reverse().join("/");
      },
    },
    {
      dataField: "updatedAt",
      text: "Updated",
      sort: true,
      formatter: data => {
        return data.slice(0, 10).split("-").reverse().join("/");
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: data => {
        return data === 1 ? (
          <span className=" badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger btn-rounded">Inactive</span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        {confirmModelPre.modelOpen && (
          <ConfirmModel
            confirmationStateAction={handleConfState}
            message={confirmModelPre.modelMessage}
            closeAction={handleClose}
          />
        )}
        <MetaTags>
          <title>User Group List | Report</title>
        </MetaTags>
        <TableView
          tableName="User Group List"
          columns={columns}
          products={products}
          filterData={{ filter, handelFilter }}
          Pagination={serverPagination}
          sortDate={{ form: filter.from, to: filter.to }}
          editAction={auth.allowEdit ? handleEdit : ""}
          deleteAction={auth.allowDelete ? handleDelete : ""}
          buttonAdd={auth.allowCreate ? tableButton : ""}
          filterFields={filterFields}
        />
      </div>
    </React.Fragment>
  );
};

export default Loader(GroupList);
