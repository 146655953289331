import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "reactstrap";

const ConfirmModel = props => {
  const [isOpen, setIsOpen] = useState(true);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (props.message) {
      setMessage(props.message);
    }
  }, [props]);
  const handleYes = () => {
    props.confirmationStateAction(true);
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        data-toggle="modal"
        centered
        //   toggle={this.togglesubscribemodal}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setIsOpen(false);
                props.closeAction(false);
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <h4 className="text-primary mb-3">Confirmation !</h4>
                  <p className="text-muted h5">{message}</p>

                  <div className="mt-4 ">
                    <Button
                      type="button"
                      className=" btn-lg btn-danger me-3"
                      onClick={() => {
                        handleYes();
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      className="ms-3 btn-lg"
                      onClick={() => {
                        props.confirmationStateAction(false);
                        setIsOpen(false);
                      }}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModel;
