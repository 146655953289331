import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import useFilter from "components/Common/useFilter";
import {
  decrypt,
  getFormId,
  parseJwt,
  urlValidation,
} from "helpers/common_helper";
import TableView from "components/Common/TableView";
import {
  getBrandDetailsAction,
  getClassificationAction,
  getPartnerAction,
  getSalesReportData,
} from "store/actions";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loader from "components/Common/Loader";
import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Model from "./Model";

const Index = props => {
  const formId = getFormId();
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });
  const [products, setProducts] = useState([]);
  const [partnerOption, setPartnerOption] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [Brand, setBrand] = useState([]);
  const [brandDEtails, setBrandDetails] = useState("");
  const [division, setDivision] = useState([]);
  const [multiSelect, setMultiSelectFilter] = useState({});
  const [userTokenDel, setUserTokenDel] = useState({});
  const [selectBrand, setSelectBrand] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [filter, handelFilter] = useFilter(1, 0, {
    pagination: { page: 1, sizePerPage: 20 },
    sortField: "Brand",
  });
  const auth = urlValidation(formId);
  const columns = [
    {
      dataField: "id",
      text: "Id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "Brand",
      text: "Brand",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectBrand(row.Brand);
          getBrandDetails();
        },
      },
    },

    {
      dataField: "SalesQty",
      text: "Sales Quantity",
    },
    {
      dataField: "InvCount",
      text: "Invoice",
      // formatter: formatter,
      // formatExtraData: commonData,
    },
    {
      dataField: "InvAvgQty",
      text: "Avg. invoice Qty.",
      sort: true,
      // formatter: formatter,
      // formatExtraData: commonData,
    },
  ];
  if (userTokenDel.userType !== "Vender") {
    columns.push(
      {
        dataField: "SalesValue",
        text: "Sales Value",
      },
      {
        dataField: "InvAvgVal",
        text: "Avg. invoice val",
      }
    );
  }

  useEffect(async () => {
    if (selectBrand) {
      props.setLoading(true);
      try {
        const encodedQueryString = decrypt({
          ...filter,
        });
        const response = await getSalesReportData(
          selectedPartner,
          JSON.stringify(multiSelect),
          encodedQueryString,
          selectBrand
        );
        if (response.status === 1) {
          if (selectBrand) {
            setBrandDetails(response);
          }
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      } catch (error) {
        props.setLoading(false);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [selectBrand]);

  const getBrandDetails = () => {
    setIsModal(true);
  };

  useEffect(async () => {
    try {
      props.setLoading(true);
      let authUser = localStorage.getItem("authUser");
      authUser = authUser ? JSON.parse(authUser) : {};

      if (authUser) {
        let authToken = authUser?.authToken || "";
        if (authToken) {
          const userDetails = parseJwt(authToken);
          setUserTokenDel(userDetails);
          if (!userDetails.masterId) {
            props.setLoading(true);
            const response = await getPartnerAction();
            if (response.status === 1) {
              let arr = response?.data || [];
              setPartnerOption(arr);
              setSelectedPartner(arr[0].id);
              props.setLoading(false);
            } else {
              props.setLoading(false);
              // toast.error(response2.msg);
            }
          } else {
            console.log(userDetails.masterId);
            setSelectedPartner(userDetails.masterId);
          }
        }
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  useEffect(async () => {
    if (selectedPartner) {
      try {
        props.setLoading(true);
        let response = await getClassificationAction(selectedPartner);
        if (response.status === 1) {
          props.setLoading(false);
          const Brand = response.data?.Division || [];
          const Division = response.data?.Brand || [];
          setBrand(Brand);
          setDivision(Division);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      } catch (error) {
        props.setLoading(false);
        console.log(error);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [selectedPartner]);

  useEffect(async () => {
    if (selectedPartner) {
      props.setLoading(true);
      try {
        const encodedQueryString = decrypt({
          ...filter,
        });
        const response = await getSalesReportData(
          selectedPartner,
          JSON.stringify(multiSelect),
          encodedQueryString
        );
        if (response.status === 1) {
          setProducts(response.data);
          setServerPagination({ ...response.metadata });
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      } catch (error) {
        props.setLoading(false);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [filter, selectedPartner, multiSelect]);

  const handlePartnerChange = partnerId => {
    props.setLoading(true);
    handelFilter({ ...filter, pagination: { ...filter.pagination, page: 1 } });
    setSelectedPartner(partnerId);
    props.setLoading(false);
  };

  const handleBrandSelect = e => {
    const { name, value } = e.target;
    setMultiSelectFilter({ ...multiSelect, [name]: value });
    handelFilter({
      ...filter,
      pagination: {
        ...filter.pagination,
        page: 1,
      },
    });
  };
  const filterComponent = () => {
    return (
      <>
        <Col sm="6" md="4">
          <div className="row py-1">
            <div className="col-3 mt-auto mb-auto pe-0">
              <Label for="Brand">Brand</Label>
            </div>
            {userTokenDel.userType !== "Vender" && (
              <Model state={isModal} setState={setIsModal} />
            )}
            <div className="col-9">
              <Select
                name="Brand"
                id="Brand"
                isMulti
                placeholder="Select Brand"
                getOptionLabel={e => (e.Division ? e.Division : "N/A")}
                getOptionValue={e => e.Division}
                onChange={e =>
                  handleBrandSelect({
                    target: {
                      name: "Brand",
                      value: e.map(val => val.Division),
                    },
                  })
                }
                options={Brand}
              />
            </div>
          </div>
        </Col>
        <Col sm="6" md="4">
          <div className="row py-1">
            <div className="col-3 mt-auto mb-auto pe-0">
              <Label for="Division">Division</Label>
            </div>

            <div className="col-9">
              <Select
                name="Division"
                id="Division"
                isMulti
                placeholder="Select Division"
                getOptionLabel={e => (e.Brand ? e.Brand : "N/A")}
                getOptionValue={e => e.Brand}
                onChange={e =>
                  handleBrandSelect({
                    target: {
                      name: "Division",
                      value: e.map(val => val.Brand),
                    },
                  })
                }
                options={division}
              />
            </div>
          </div>
        </Col>
      </>
    );
  };
  return (
    <div className="page-content">
      <MetaTags>
        <title>Sales Report</title>
      </MetaTags>
      <div className="container-fluid">
        {/* <Breadcrumbs title="Sales" breadcrumbItem="Sales Report" /> */}

        <TableView
          tableName="Sales Report"
          columns={columns}
          products={products}
          filterData={{ filter, handelFilter }}
          Pagination={serverPagination}
          sortDate={{ form: filter.from, to: filter.to }}
          partner={
            !userTokenDel.masterId && {
              option: partnerOption,
              handleChange: handlePartnerChange,
              defaultPartner: selectedPartner,
            }
          }
          defaultSorted={[
            {
              dataField: "Brand",
              order: "asc",
            },
          ]}
          filterComponent={Brand && filterComponent}
        />
      </div>
    </div>
  );
};

export default Loader(Index);
