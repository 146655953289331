import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const DataGraph = props => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  useEffect(() => {
    console.log(props.data);
    if (Object.keys(props.data).length > 0) {
      const chartsData = props.data.series;
      const chartOption = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        colors: ["#2e82f3", "#FFA500", "#FF0000"],
        xaxis: {
          categories: props.data.labels,
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };

      setSeries(chartsData);
      setOptions(chartOption);
    }
  }, [props]);

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default DataGraph;
