import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";
import { MdContactEmergency } from "react-icons/md";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ isDashboard: !this.state.isDashboard });
                      }}
                      to="/panel/dashboard"
                    >
                      <i className="bx bx-customize me-2" />
                      {this.props.t("Apps")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isDashboard,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              emailState: !this.state.emailState,
                            });
                          }}
                        >
                          {this.props.t("Administrator")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.emailState,
                          })}
                        >
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              to="/#"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  emailState: !this.state.emailState,
                                });
                              }}
                            >
                              <span key="t-email-templates">User Group</span>{" "}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: this.state.emailState,
                              })}
                            >
                              <Link
                                to="/email-template-basic"
                                className="dropdown-item"
                              >
                                {this.props.t("Add")}
                              </Link>
                              <Link
                                to="/email-template-alert"
                                className="dropdown-item"
                              >
                                {this.props.t("List")}
                              </Link>
                              <Link
                                to="/email-template-billing"
                                className="dropdown-item"
                              >
                                {this.props.t("Edit")}
                              </Link>
                            </div>
                          </div>
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              to="/#"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  emailState: !this.state.emailState,
                                });
                              }}
                            >
                              <span key="t-email-templates">User</span>{" "}
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: this.state.emailState,
                              })}
                            >
                              <Link
                                to="/email-template-basic"
                                className="dropdown-item"
                              >
                                {this.props.t("Add")}
                              </Link>
                              <Link
                                to="/email-template-alert"
                                className="dropdown-item"
                              >
                                {this.props.t("List")}
                              </Link>
                              <Link
                                to="/email-template-billing"
                                className="dropdown-item"
                              >
                                {this.props.t("Edit")}
                              </Link>
                            </div>
                          </div>
                          <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              to="/#"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  emailState: !this.state.emailState,
                                });
                              }}
                            >
                              <span key="t-email-templates">Partner</span>
                              <div className="arrow-down"></div>
                            </Link>
                            <div
                              className={classname("dropdown-menu", {
                                show: this.state.emailState,
                              })}
                            >
                              <Link
                                to="/email-template-basic"
                                className="dropdown-item"
                              >
                                {this.props.t("Add")}
                              </Link>
                              <Link
                                to="/email-template-alert"
                                className="dropdown-item"
                              >
                                {this.props.t("List")}
                              </Link>
                              <Link
                                to="/email-template-billing"
                                className="dropdown-item"
                              >
                                {this.props.t("Edit")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ appState: !this.state.appState });
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="me-2">
                        <MdContactEmergency />
                      </i>
                      {this.props.t("Agent")} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.appState,
                      })}
                    >
                      <Link to="/calendar" className="dropdown-item">
                        {this.props.t("Add")}
                      </Link>
                      <Link to="/chat" className="dropdown-item">
                        {this.props.t("list")}
                      </Link>
                      <Link to="/apps-filemanager" className="dropdown-item">
                        {this.props.t("Edit")}
                      </Link>
                    </div>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
