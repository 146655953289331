import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";

const Loader = WrappedComponent => {
  function IsLoading(props) {
    const [loading, setLoading] = useState(false);
    const setLoadingState = isComponentLoading => {
      setLoading(isComponentLoading);
    };
    return (
      <>
        {loading && (
          <div
            className=""
            style={{
              display: "flex",
              position: "fixed",
              zIndex: "2000",
              justifyContent: "center",
              alignItems: "center",
              width: "83vw",
              height: "100%",
              background: "black",
              background: "rgba(0,0,0,0.7)",
            }}
          >
            (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
            )
          </div>
        )}
        <WrappedComponent {...props} setLoading={setLoadingState} />
      </>
    );
  }
  return IsLoading;
};

export default Loader;
