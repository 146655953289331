import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { uploadProfileImgAction } from "store/actions";
import { toast } from "react-toastify";

const ProfileImg = props => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const initialValues = {
    image: "",
  };
  useEffect(() => {
    if (props.Open) {
      setModal(props.Open);
    }
  }, [props]);
  const toggle = () => {
    setModal(!modal);
    props.handleClose(!modal);
    setSelectedImage(null);
  };

  const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];
  const maxSize = 500 * 1024; // 500KB

  const validateImage = file => {
    if (!supportedFormats.includes(file.type)) {
      return "Invalid file format. Please select a JPEG, JPG, or PNG image.";
    }

    if (file.size > maxSize) {
      return "File size exceeds the limit of 500KB.";
    }

    return "";
  };

  const validationSchema = Yup.object().shape({
    image: Yup.mixed()
      .required("Please select an image")
      .test("fileSize", "File size exceeds the limit of 500KB", value => {
        if (value) {
          return value.size <= maxSize;
        }
        return true;
      })
      .test(
        "fileFormat",
        "Invalid file format. Please select a JPEG, JPG, or PNG image",
        value => {
          if (value) {
            return supportedFormats.includes(value.type.toLowerCase());
          }
          return true;
        }
      ),
  });

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    const error = validateImage(file);

    if (error) {
      // console.log("Validation Error:", error);
      return toast.error(error);
    }

    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
  };

  const handleSubmit = async values => {
    try {
      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = reader.result;
        const response = await uploadProfileImgAction({ image: base64Data });

        if (response.status === 1) {
          setLoading(false);
          toast.success(response.msg);
          localStorage.setItem("profile", JSON.stringify(response.data));
          toggle();
        } else {
          setLoading(false);
          toast.error(response.msg);
        }
      };
      reader.readAsDataURL(values.image);
    } catch (error) {
      console.log(error);
      setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered size="sm">
        <ModalHeader toggle={toggle}>Update Profile</ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <ModalBody>
                <div className="d-flex flex-column align-items-center">
                  <Button type="button">
                    <Label htmlFor="image" className="m-0">
                      Select a image
                    </Label>
                    <input
                      type="file"
                      className="d-none"
                      id="image"
                      name="image"
                      accept="image/jpeg, image/jpg, image/png"
                      onChange={e => handleImageChange(e, setFieldValue)}
                    />
                  </Button>

                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className="avatar-xl mt-3 rounded-circle img-thumbnail"
                    />
                  )}

                  <ErrorMessage
                    name="image"
                    className="text-danger"
                    component="div"
                  />
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center">
                <div>
                  {values.image && (
                    <Button
                      color="primary"
                      className=""
                      type="submit"
                      disabled={Loading}
                    >
                      Upload
                    </Button>
                  )}
                </div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ProfileImg;
