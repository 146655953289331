import { toast } from "react-toastify";
import ConfirmModel from "../../components/Common/ConfirmModel";
import data from "../../common/data/TableView";
import TableView from "../../components/Common/TableView";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import {
  decrypt,
  getFormId,
  parseJwt,
  urlValidation,
} from "helpers/common_helper";
import {
  getPartnerAction,
  getUgListAction,
  getUserAction,
  resetPassAction,
} from "store/actions";
import Loader from "components/Common/Loader";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useFilter from "components/Common/useFilter";
import { selectFilter } from "react-bootstrap-table2-filter";

const UserList = props => {
  const filterFields = [];
  const [userId, setUserId] = useState("");
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    data: "",
  });
  const [confirmModelPre, setConfModelPro] = useState({
    modelMessage: "",
    confirmationState: false,
    modelOpen: false,
  });
  const formId = getFormId();
  const auth = urlValidation(formId);
  const [products, setProducts] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [partner, setPartner] = useState([]);
  const [filter, handelFilter] = useFilter(6);
  const [userTokenDel, setUserTokenDel] = useState([]);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });

  useEffect(async () => {
    props.setLoading(true);
    let authUser = localStorage.getItem("authUser");
    authUser = authUser ? JSON.parse(authUser) : {};

    if (authUser) {
      let authToken = authUser?.authToken || "";
      let partner = authUser?.partner || "";
      if (authToken) {
        const userDetails = parseJwt(authToken);
        setUserTokenDel(userDetails);
      }
    }
  }, []);

  useEffect(async () => {
    props.setLoading(true);
    try {
      const encodedQueryString = decrypt(filter);
      const response = await getUserAction("", encodedQueryString);
      if (response.status === 1) {
        setServerPagination({ ...response.metadata });
        setProducts(response.data);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [filter]);

  const tableButton = {
    text: "Add User",
    path: "user/add",
  };
  const history = useHistory();

  const handleEdit = row => {
    history.push(`/panel/user/add/${row.id}`);
  };
  const handleResetPassword = async row => {
    const item = products.filter(value => value.id == row.id);
    setUserId(row.id);
    setConfModelPro({
      ...confirmModelPre,
      modelMessage: `Are you sure you want to reset ${item[0].fName} ${item[0].lName}'s password`,
      modelOpen: true,
    });
    setModal({
      ...modal,
      message: `${item[0].fName} ${item[0].lName}`,
    });
  };

  useEffect(async () => {
    console.log(userTokenDel.masterId);
    if (userTokenDel.masterId === null) {
      try {
        let response1 = await getUgListAction();
        if (response1.status === 1) {
          let arr = response1?.data || [];
          setUserGroup(arr);
        }

        const response2 = await getPartnerAction();
        if (response2.status === 1) {
          let arr = response2?.data || [];
          setPartner(arr);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response2.msg);
        }
      } catch (error) {
        props.setLoading(false);
        console.log(error);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [userTokenDel]);

  const handleConfState = async stateValue => {
    try {
      setConfModelPro({
        ...confirmModelPre,
        confirmationState: stateValue,
        modelOpen: false,
      });
      if (stateValue) {
        props.setLoading(true);
        const response = await resetPassAction({ formId: formId }, userId);
        if (response.status === 1) {
          setModal({ ...modal, isOpen: true });
          props.setLoading(false);
          toast.success(response.msg);
          setModal({
            ...modal,
            isOpen: true,
            data: response.data,
          });
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      console.log(error);
      toast.error(err);
    }
  };
  const handleClose = modelState => {
    setConfModelPro({ ...confirmModelPre, modelOpen: modelState });
  };

  const handleCopyPass = () => {
    setModal({ ...modal, isOpen: false });
    navigator.clipboard.writeText(modal.data);
    toast.success("Password Copied");
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "fName",
      text: "Name",
      sort: true,
      formatter: (date, row) => {
        return `${date} ${row.lName}`;
      },
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "mobileNo",
      text: "Mobile Number",
    },
    {
      dataField: "UserGroupName",
      text: "User group",
    },
    {
      dataField: "userType",
      text: "User Type",
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: data => {
        return data === 1 ? (
          <span className=" badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger btn-rounded">Inactive</span>
        );
      },
    },
  ];

  if (!userTokenDel.masterId && partner.length > 0) {
    let partnerOptions = partner.map(val => {
      return {
        value: val.companyName,
        label: val.companyName,
      };
    });
    partnerOptions.unshift({ value: "", label: "All" });

    partner.length < partnerOptions.length &&
      columns.splice(5, 0, {
        dataField: "partnerName",
        text: "Partner",
        formatter: val => {
          return val == null ? (
            <div className="d-flex align-items-center gap-1">
              <span className="material-icons-outlined">
                admin_panel_settings
              </span>
              <span>Admin</span>
              <span>(No Partner Exist)</span>
            </div>
          ) : (
            val
          );
        },
      });

    let ugOptions = userGroup.map(val => {
      return { value: val.name, label: val.name };
    });
    ugOptions.unshift({ value: "", label: "All" });

    filterFields.push(
      {
        dataField: "userType",
        text: "User Type",
        filter: [
          { value: "", label: "All" },
          { value: "Admin", label: "Admin" },
          { value: "Partner-User", label: "Partner User" },
          { value: "Vender", label: "Vender" },
        ],
        defaultValue: "",
      },
      {
        dataField: "partnerName",
        text: "Partner",
        filter: partnerOptions,
        defaultValue: "",
      },
      {
        dataField: "UserGroupName",
        text: "User Group",
        filter: ugOptions,
        defaultValue: "",
      }
    );
  } else {
    filterFields.push({
      dataField: "userType",
      text: "User Type",
      filter: [
        { value: "", label: "All" },
        { value: "Partner-User", label: "Partner User" },
        { value: "Vender", label: "Vender" },
      ],
      defaultValue: "",
    });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Modal isOpen={modal.isOpen}>
          <ModalHeader toggle={() => setModal({ ...modal, isOpen: false })}>
            Password reset
          </ModalHeader>
          <ModalBody>
            {`${modal.message} password reset password successfully password is `}
            <strong>{modal.data}</strong>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleCopyPass}>
              Copy
            </Button>
          </ModalFooter>
        </Modal>
        {confirmModelPre.modelOpen && (
          <ConfirmModel
            confirmationStateAction={handleConfState}
            message={confirmModelPre.modelMessage}
            closeAction={handleClose}
          />
        )}
        <MetaTags>
          <title>User List | Report</title>
        </MetaTags>
        <TableView
          tableName="User List"
          columns={columns}
          products={products}
          filterData={{ filter, handelFilter }}
          Pagination={serverPagination}
          sortDate={{ form: filter.from, to: filter.to }}
          editAction={auth.allowEdit ? handleEdit : ""}
          resetPasswordAction={auth.allowEdit ? handleResetPassword : ""}
          buttonAdd={auth.allowCreate ? tableButton : ""}
          filterFields={filterFields}
        />
      </div>
    </React.Fragment>
  );
};

export default Loader(UserList);
