const publicRoute = [
  "panel/dashboard",
  "panel/user/profile",
  "panel/user/changepassword",
];

export const getTokenDetails = () => {
  let localData = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {};
  const accessToken = localData.accessToken ? localData.accessToken : "";
  let tokenData = null;
  if (accessToken) {
    let base64Url = accessToken.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    tokenData = JSON.parse(jsonPayload);
  }
  return tokenData;
};
export const parseJwt = token => {
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } else {
    return false;
  }
};
export const getFormId = () => {
  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : {};
  let formId = "";
  if (authUser) {
    let formUrls = authUser?.formVsUrl || [];
    if (formUrls.length > 0) {
      let winPathArr = window.location.pathname.split("/");
      winPathArr = winPathArr.filter(v => v != "");
      let path = winPathArr.length > 0 ? `/${winPathArr[0]}` : "";
      path = winPathArr.length > 1 ? `${path}/${winPathArr[1]}` : path;
      path = winPathArr.length > 2 ? `${path}/${winPathArr[2]}` : path;
      if (path !== "") {
        let fltUrl = formUrls.filter(v => v.url === path);
        formId = fltUrl.length > 0 ? fltUrl[0].fid : "";
      }
    }
  }
  return formId;
};
export const urlValidation = id => {
  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : {};
  let winPathArr = window.location.pathname.split("/");
  winPathArr = winPathArr.filter(v => v != "").join("/");
  const publicRouteExist = publicRoute.includes(winPathArr);
  if (publicRouteExist) {
    return true;
  }
  let form = "";
  if (authUser) {
    let formUrls = authUser?.formVsUrl || [];
    if (id) {
      formUrls = formUrls.filter(val => val.fid == id);
      return formUrls[0];
    } else {
      return window.location.replace("/panel/dashboard");
    }
  }
};

// export const csvValidation = csvData => {
//   let validate = new RegExp("^$");
//   let error = [];
//   for (let i = 0; i < csvData.length; i++) {
//     for (const key in csvData[i]) {
//       if (Object.hasOwnProperty.call(csvData[i], key)) {
//         const element = csvData[i][key];
//         if (key === "Registration Numbers") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Customer Name") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Loan No") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Make") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "EMI") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "POS") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Chasis Number") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Engine Number") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Bucket") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "1st Confirmer Name") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "2nd Confirmer Name") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "3rd Confirmer Name") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "1st Confirmer No") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "2nd Confirmer No") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "3rd Confirmer No") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Branch") {
//           console.log(validate.test(element));
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Model") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Sec 17") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Seasoning") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "TBR") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Allocation") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else if (key === "Address") {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} empty value`)
//             : "";
//         } else {
//           validate.test(element)
//             ? error.push(`${i + 1} row ${key} Invalid column`)
//             : "";
//         }
//       }
//     }
//   }
//   return error;
// };

export const decrypt = filter => {
  const params = new URLSearchParams();

  Object.entries(filter).forEach(([key, value]) => {
    params.append(key, JSON.stringify(value));
  });

  const queryString = params.toString();
  return btoa(queryString);
};
