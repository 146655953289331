import React, { useCallback, useMemo, useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

function formatDate() {
  let d = new Date();
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const AgGrid = props => {
  const filter = props.filterData?.filter || "";
  const handelFilter = props.filterData?.handelFilter || "";

  const handleChange = e => {
    const { name, value } = e.target;
    handelFilter({
      ...filter,
      [name]: value,
    });
  };
  const handelFilterField = e => {
    console.log(e);
  };

  const gridRef = useRef();
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "1000px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);

  // Example of consuming Grid Event
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 80,
    };
  }, []);

  const CellValueChangedEvent = e => {
    const { newValue, oldValue, data } = e;
    const userRefCols = e?.column?.userProvidedColDef || {};
    if (props.cellEditChangeCallBack) {
      props.cellEditChangeCallBack(newValue, oldValue, data, userRefCols);
    }
  };
  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      resizable: true,
    };
  }, []);

  return (
    <>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row className="mb-2">
                {props.tableName && (
                  <Col sm="6" md="9">
                    <div className="h2">{props.tableName}</div>
                  </Col>
                )}
                <Col
                  sm="6"
                  md="3"
                  className="d-flex align-items-center justify-content-end"
                >
                  {props.buttonAdd && (
                    <Button
                      className="btn btn-primary btn-lg"
                      onClick={() =>
                        history.push(`/panel/${props.buttonAdd.path}`)
                      }
                    >
                      <AiOutlinePlus />
                      {props.buttonAdd.text}
                    </Button>
                  )}
                  {props.exportAction && (
                    <Button
                      className="btn btn-primary d-flex btn-lg"
                      onClick={props.exportAction}
                    >
                      <span className="material-icons-outlined me-1">
                        file_download
                      </span>
                      <span className="fs-6 fw-bold">Export Data</span>
                    </Button>
                  )}
                </Col>
              </Row>

              <Row className="mb-2">
                {props.search && (
                  <Col sm="6" md="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <Input
                          type="text"
                          placeholder="search"
                          name="search"
                          onChange={e => {
                            props.search(e.target.name, e.target.value);
                          }}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                )}
                {props.sortDate && (
                  <Col sm="6" md="4">
                    <div className="row py-1">
                      <div className="col-3 mt-auto mb-auto pe-0">
                        <label
                          htmlFor="fromDate"
                          className="mb-0 me-2 fs-6 fw-bold"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          From Date
                        </label>
                      </div>
                      {console.log(props.sortDate)}
                      <div className="col-9">
                        <input
                          className=" form-control"
                          id="fromDate"
                          name="from"
                          placeholder="date placeholder"
                          type="date"
                          max={formatDate()}
                          value={filter.from}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                {props.sortDate && (
                  <Col sm="6" md="4">
                    <div className="row py-1">
                      <div className="col-3 mt-auto mb-auto pe-0">
                        <Label
                          for="toDate"
                          className="mb-0 me-2 fs-6 fw-bold"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          To Date
                        </Label>
                      </div>
                      <div className="col-9">
                        <Input
                          id="toDate"
                          name="to"
                          placeholder="date placeholder"
                          type="date"
                          max={formatDate()}
                          value={filter.to}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                {props.partner && (
                  <Col sm="6" md="4">
                    <div className="row py-1">
                      <div className="col-3 mt-auto mb-auto pe-0">
                        <Label
                          for="partner"
                          className="mb-0 me-2 fs-6 fw-bold"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Partner
                        </Label>
                      </div>
                      <div className="col-9">
                        <select
                          name="partner"
                          id="partner"
                          className="form-select"
                          value={props.partner.defaultPartner}
                          onChange={e =>
                            props.partner.handleChange(e.target.value)
                          }
                        >
                          {props.partner.option.map(obj => (
                            <option key={obj.id} value={obj.id}>
                              {obj.companyName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col>
                )}
                {props.filterFields &&
                  props.filterFields.map((field, index) => (
                    <Col sm="6" md="4" key={index}>
                      {field.filter ? (
                        <div className="row py-1">
                          <div className="col-3 mt-auto mb-auto pe-0">
                            <Label
                              for={field.dataField}
                              className="mb-0 me-2 fs-6 fw-bold"
                              style={labelWrap}
                            >
                              {field.text}
                            </Label>
                          </div>
                          <div className="col-9">
                            <select
                              name={field.dataField}
                              id={field.dataField}
                              className="form-select"
                              value={
                                filter.searchText[field.dataField] ||
                                field.defaultValue
                              }
                              onChange={handelFilterField}
                            >
                              {field.filter.map(obj => (
                                <option key={obj.label} value={obj.value}>
                                  {obj.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ) : (
                        <div className="row py-1">
                          <div className="col-3 mt-auto mb-auto pe-0">
                            <Label
                              for={field.dataField}
                              className="mb-0 me-2 fs-6 fw-bold"
                              style={labelWrap}
                            >
                              {field.text}
                            </Label>
                          </div>
                          <div className="col-9">
                            <Input
                              id={field.dataField}
                              name={field.dataField}
                              placeholder={`${field.placeholder || field.text}`}
                              type="text"
                              onChange={handelFilterField}
                            />
                          </div>
                        </div>
                      )}
                    </Col>
                  ))}

                {props.filterComponent && props.filterComponent()}
              </Row>
              <Row>
                <div className="content">
                  <Row md={1}>
                    {/* <Col>
                      <Row md={3}>
                        <Col>
                          <input
                            type="text"
                            id="filter-text-box"
                            placeholder="Search in All column"
                            className="form-control my-3"
                            onInput={onFilterTextBoxChanged}
                          />
                        </Col>
                      </Row>
                    </Col> */}
                    <Col>
                      <div style={containerStyle}>
                        <div style={gridStyle} className="ag-theme-alpine">
                          <AgGridReact
                            ref={gridRef}
                            cacheQuickFilter={
                              props?.dataSet?.agGridComp?.cacheQuickFilter ||
                              false
                            }
                            rowDragManaged={
                              props?.dataSet?.agGridComp?.rowDragManaged ||
                              false
                            }
                            animateRows={
                              props?.dataSet?.agGridComp?.animateRows || false
                            }
                            rowGroupPanelShow={
                              props?.dataSet?.agGridComp?.rowGroupPanelShow ||
                              false
                            }
                            onCellValueChanged={CellValueChangedEvent}
                            rowData={props?.dataSet?.rows}
                            columnDefs={props?.dataSet?.columns}
                            defaultColDef={
                              props?.dataSet?.agGridComp?.defCols || false
                            }
                            autoGroupColumnDef={autoGroupColumnDef}
                            sideBar={
                              props?.dataSet?.agGridComp?.sideBar || false
                            }
                            enableRangeSelection={
                              props?.dataSet?.agGridComp
                                ?.enableRangeSelection || false
                            }
                            enableFillHandle={
                              props?.dataSet?.agGridComp?.enableFillHandle ||
                              false
                            }
                            enableCharts={
                              props?.dataSet?.agGridComp?.enableCharts || false
                            }
                            statusBar={
                              props?.dataSet?.agGridComp?.statusPanels || false
                            }
                            pagination={true}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AgGrid;
