import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "reactstrap";

const Model = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (props.state) {
      setIsOpen(props.state);
    }
    if (props.data) {
      setProducts(props.data);
    }
  }, [props]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        data-toggle="modal"
        centered
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setIsOpen(false);
                props.setState(!props.state);
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <h4 className="text-primary mb-3">Confirmation !</h4>
                  <p className="text-muted h5">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Saepe distinctio natus eligendi aliquam in corporis! Esse
                    dolor ab architecto quod vero accusantium laboriosam earum
                    amet, eligendi praesentium aliquam eum ea aspernatur
                    repellat magnam repudiandae sed delectus quaerat provident
                    minima itaque suscipit ipsa? Facere vel illum dolorum
                    tempore voluptas reprehenderit repellat non, perferendis
                    deserunt eligendi hic, harum itaque ut iure similique?
                    Repudiandae explicabo placeat aut excepturi odio corrupti
                    nobis at, fuga modi ex soluta mollitia libero distinctio
                    expedita autem, iusto, facilis deserunt! Nobis maxime
                    commodi dolores totam deleniti numquam atque, consectetur
                    dolorum assumenda dolore temporibus, voluptatem adipisci
                    placeat accusamus? Incidunt cumque eveniet quos voluptatum
                    reiciendis recusandae culpa ab a totam vitae porro magni est
                    voluptatem ea blanditiis impedit maiores et, minus eaque
                    architecto velit inventore repudiandae? Iure molestiae,
                    autem veritatis fugiat voluptates consectetur ducimus, ea in
                    quas, aliquid libero magnam at. Deleniti, aspernatur? Iusto
                    quos non quod deleniti at voluptatibus libero facere
                    voluptas ut sequi, esse eius quis incidunt et consequatur
                    sapiente, perspiciatis tenetur, excepturi ex. Temporibus est
                    nostrum dolorum illum quam numquam molestiae corrupti natus
                    repellat quas beatae sint, repudiandae quod? Dolores sequi
                    quisquam neque recusandae quaerat, mollitia quos dolorem quo
                    ut eveniet laudantium numquam est blanditiis dignissimos,
                    eum quia!
                  </p>

                  {/* <div className="mt-4 ">
                    <Button
                      type="button"
                      className=" btn-lg btn-danger me-3"
                      onClick={() => {
                        handleYes();
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      className="ms-3 btn-lg"
                      onClick={() => {
                        props.confirmationStateAction(false);
                        setIsOpen(false);
                      }}
                    >
                      No
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Model;
