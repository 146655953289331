import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import * as Yup from "yup";

const AddColumnModal = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [columnProperties, setColumnProperties] = useState("");
  useEffect(() => {
    if (props.isOpen) {
      setIsOpen(props.isOpen);
    }
    if (props.columnProperties.length > 0) {
      setColumnProperties(props.columnProperties);
    }
  }, [props]);

  const handleChange = event => {
    const newText = event.target.value;
    updatePreview(newText);
  };
  const updatePreview = text => {
    try {
      const json = JSON.parse(text);
      setColumnProperties(JSON.stringify(json, null, 2));
      setIsValid(true);
    } catch (error) {
      setIsValid(false);
    }
  };

  const handleKeyDown = event => {
    event.preventDefault();
    if (
      event.key === "{" ||
      event.key === "[" ||
      event.key === '"' ||
      event.key === "'"
    ) {
      const { selectionStart, selectionEnd, value } = event.target;
      console.log(selectionStart, selectionEnd, value);
      const startValue = value.substring(0, selectionStart);
      const endValue = value.substring(selectionEnd);
      const newValue = startValue + event.key + endValue;
      setColumnProperties(newValue);
      const newPosition = selectionStart + 1;
      event.target.setSelectionRange(newPosition, newPosition);
      updatePreview(newValue);
    } else {
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg"
      autoFocus={"true"}
      data-toggle="modal"
      centered
      //   toggle={this.togglesubscribemodal}
    >
      <div className="modal-content">
        <div className="modal-header border-bottom-0">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setIsOpen(false);
              props.closeAction(false);
            }}
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col>
              <div className="h4 text-center">Add Column</div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={12}>
                      <FormGroup>
                        <Label>Column Property JSON</Label>
                        <textarea
                          name="columnProperties"
                          className="form-control"
                          rows={5}
                          cols={50}
                          value={columnProperties}
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                        ></textarea>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default AddColumnModal;
