import React, { useRef, useEffect, useState } from "react";
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import { withRouter, Link, useHistory } from "react-router-dom";
import { getFormId, urlValidation } from "helpers/common_helper";
//i18n
const SidebarContent = props => {
  const refDiv = useRef();
  const formId = getFormId();
  const auth = urlValidation(formId);

  const [sideMenuList, setSideMenuList] = useState({});
  const [activeMenuInd, setActiveMenuInd] = useState({ p1: "", p2: "" });
  useEffect(async () => {
    let sideMenu = localStorage.getItem("authUser");
    sideMenu = sideMenu ? JSON.parse(sideMenu) : {};
    if (sideMenu) {
      setSideMenuList(sideMenu?.sideMenu || {});
    }
  }, [localStorage.getItem("authUser")]);

  useEffect(() => {
    const sideMenu = Object.assign({}, sideMenuList);
    for (let i in sideMenu) {
      let p1 = sideMenu[i].children;
      let indexes = { p1: "", p2: 0 };
      for (let j in p1) {
        let p2 = p1[j].children;
        for (let k in p2) {
          if (
            props.location.pathname.indexOf(p2[k].formUrl) >= 0 &&
            p2[k].formUrl.length > 3
          ) {
            indexes = { p1: j, p2: p2[k].formId };
          }
        }
      }
      setActiveMenuInd(indexes);
    }
  }, [props, sideMenuList]);
  const menuClicked = (e, p1, p2) => {
    e.preventDefault();
    if (p2) {
      setActiveMenuInd({
        ...activeMenuInd,
        p2: p2,
      });
    } else {
      setActiveMenuInd({
        ...activeMenuInd,
        p1: p1 === activeMenuInd.p1 ? "" : p1,
      });
    }
    return false;
  };
  const menuClicked2 = (e, p1, p2) => {
    e.preventDefault();
    if (p2) {
      setActiveMenuInd({
        ...activeMenuInd,
        p2: p2,
      });
    } else {
      setActiveMenuInd({
        ...activeMenuInd,
        p1: p1 === activeMenuInd.p1 ? "" : p1,
      });
    }
    return false;
  };
  return (
    <React.Fragment>
      <SimpleBar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li
              className={activeMenuInd.p2 === 0 ? "mm-active" : ""}
              onClick={() => setActiveMenuInd({ p1: "", p2: 0 })}
            >
              <Link
                to="/panel/dashboard"
                className={activeMenuInd.p2 === 0 ? "active" : ""}
              >
                <i className="bx bx-home-circle" />
                <span>Dashboard</span>
              </Link>
            </li>
            {Object.keys(sideMenuList).map(v => {
              return (
                <div key={sideMenuList[v].id}>
                  <li className="menu-title">{sideMenuList[v].name}</li>
                  {Object.keys(sideMenuList[v].children).map(v2 => {
                    return (
                      <li
                        key={sideMenuList[v].children[v2].id}
                        className={v2 === activeMenuInd.p1 ? "mm-active" : ""}
                      >
                        <Link
                          to="/#"
                          className={
                            v2 === activeMenuInd.p1
                              ? "has-arrow"
                              : "has-arrow mm-collapsed"
                          }
                          onClick={e => menuClicked(e, v2)}
                          aria-expanded={v2 === activeMenuInd.p1 ? true : false}
                        >
                          <i className={sideMenuList[v].children[v2].className}>
                            {sideMenuList[v].children[v2].icon}
                          </i>
                          <span>{sideMenuList[v].children[v2].name}</span>
                        </Link>
                        <ul
                          className={
                            v2 === activeMenuInd.p1
                              ? "sub-menu"
                              : "sub-menu  mm-collapse"
                          }
                          aria-expanded={v2 === activeMenuInd.p1 ? true : false}
                        >
                          {sideMenuList[v].children[v2].children.map(v3 => {
                            return (
                              (v3.allowView && (
                                <li key={v3.formId}>
                                  <Link
                                    to={`${v3.formUrl}`}
                                    className={
                                      v3.formId === activeMenuInd.p2
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <i className={v3.className}>{v3.icon}</i>
                                    <span>{v3.name}</span>
                                  </Link>
                                </li>
                              )) ||
                              ""
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </div>
              );
            })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
