import { toast } from "react-toastify";
import TableView from "../../components/Common/TableView";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import {
  getCityListAction,
  getCountryListAction,
  getPartnerAction,
  getStateListAction,
} from "store/actions";
import Loader from "components/Common/Loader";
import { getFormId, urlValidation } from "helpers/common_helper";
import useFilter from "components/Common/useFilter";

const PartnerList = props => {
  const [products, setProducts] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const formId = getFormId();
  const auth = urlValidation(formId);
  const [filter, handelFilter] = useFilter(6);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });

  useEffect(async () => {
    props.setLoading(true);
    try {
      const params = new URLSearchParams();
      Object.entries(filter).forEach(([key, value]) => {
        params.append(key, JSON.stringify(value));
      });

      const queryString = params.toString();
      const encodedQueryString = btoa(queryString);
      const response = await getPartnerAction("", encodedQueryString);
      if (response.status === 1) {
        setServerPagination({ ...response.metadata });
        setProducts(response.data);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [filter]);

  useEffect(async () => {
    try {
      props.setLoading(true);
      const country = await getCountryListAction();
      const state = await getStateListAction();
      const city = await getCityListAction();
      if (country.status === 1 || state.state === 1 || city.state === 1) {
        props.setLoading(false);
        let arr1 = country?.data || [];
        let arr2 = state?.data || [];
        let arr3 = city?.data || [];
        setCommonData([...arr1, ...arr2, ...arr3]);
      } else {
        toast.error(country.msg);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const tableButton = {
    text: "Add Partner",
    path: "partner/add",
  };
  const history = useHistory();

  const handleEdit = row => {
    history.push(`/panel/partner/add/${row.id}`);
  };
  const formatter = (cell, row, rowIndex, extraData) => {
    let a = extraData.filter(val => val.id == cell);
    if (extraData.length > 0) {
      return a[0].name;
    }
  };
  const filterFields = [
    {
      dataField: "companyName",
      text: "Com. Name",
      placeholder: "Company Name",
    },
    { dataField: "gstNumber", text: "GST No." },
  ];
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "companyName",
      text: "Company name",
    },
    {
      dataField: "printName",
      text: "Print name",
    },
    {
      dataField: "gstNumber",
      text: "GST Number",
    },
    {
      dataField: "cityId",
      text: "City",
      formatter: formatter,
      formatExtraData: commonData,
    },
    {
      dataField: "stateId",
      text: "State",
      formatter: formatter,
      formatExtraData: commonData,
    },
    {
      dataField: "countryId",
      text: "Country",
      formatter: formatter,
      formatExtraData: commonData,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: data => {
        return data === 1 ? (
          <span className=" badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger btn-rounded">Inactive</span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Partner List | Report</title>
        </MetaTags>
        <TableView
          tableName="Partner List"
          columns={columns}
          products={products}
          filterData={{ filter, handelFilter }}
          Pagination={serverPagination}
          sortDate={{ form: filter.from, to: filter.to }}
          editAction={auth.allowEdit && handleEdit}
          buttonAdd={auth.allowCreate ? tableButton : ""}
          filterFields={filterFields}
        />
      </div>
    </React.Fragment>
  );
};

export default Loader(PartnerList);
