import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ApexRadial from "./ApexRadial";
import { getPaySummary } from "store/actions";
import ReactApexChart from "react-apexcharts";

const MonthlyEarning = props => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [graphData, setGraphData] = useState({});
  const [filterDate, setFilterDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [show, setShow] = useState(false);

  useEffect(async () => {
    try {
      if (props.id && filterDate) {
        const response = await getPaySummary(props.id, filterDate);
        if (response.status === 1) {
          setGraphData(response.data);
        } else {
          // toast.error(response2.msg);
        }
      }
    } catch (error) {}
  }, [props, filterDate]);

  useEffect(() => {
    if (Object.keys(graphData).length > 0) {
      const chartsData = graphData.series;
      const chartOption = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#2e82f3"],
        xaxis: {
          categories: graphData.labels,
        },
        yaxis: {
          title: {
            text: "Amount",
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
      setSeries(chartsData);
      setOptions(chartOption);
    }
  }, [graphData]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterDate(value);
  };
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <Row className="position-relative">
            <div
              className="filter-btn-container w-auto p-0"
              style={{ top: "-10px" }}
              onClick={() => setShow(!show)}
            >
              <div className="btn btn-primary btn-sm filter-btn">
                {show ? (
                  <span className="material-icons-outlined btn-icon">
                    close
                  </span>
                ) : (
                  <span className="material-icons-outlined btn-icon">
                    filter_alt
                  </span>
                )}
              </div>
            </div>
            <CardTitle className="mb-4 h4 col-6">Payment Wise sales</CardTitle>
            <div className={`form-group col-6 ${show ? "" : "d-none"}`}>
              <label htmlFor={`filterDate`} className="mb-1">
                From Date:
              </label>
              <input
                type="date"
                className="form-control mb-1"
                name="filterDate"
                id={`filterDate`}
                value={filterDate}
                onChange={e => handleChange(e)}
              />
            </div>
          </Row>
          <Row>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
              className="apex-charts"
            />
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonthlyEarning;
