const keyValueOption = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "array", label: "Array" },
  { value: "arrayofobject", label: "Array of Object" },
  { value: "data", label: "Date" },
  { value: "time", label: "Time" },
  { value: "date-time", label: "Date Time" },
  { value: "image", label: "Image" },
  { value: "boolean", label: "Boolean" },
];

export { keyValueOption };
