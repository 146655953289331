import Breadcrumbs from "components/Common/Breadcrumb";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import { keyValueOption } from "common/data/reports";
import { decrypt, getFormId, parseJwt } from "helpers/common_helper";
import Loader from "components/Common/Loader";
import {
  getPartnerAction,
  getReportAction,
  saveReportAction,
} from "store/actions";
import { toast } from "react-toastify";
import AddColumnModal from "./AddColumnModal";

const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      resolve(base64String);
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];
const maxSize = 500 * 1024; // 500KB

const validateImage = file => {
  if (!file) {
    return "Please select an image.";
  }

  if (!supportedFormats.includes(file.type)) {
    return "Invalid file format. Please select a JPEG, JPG, or PNG image.";
  }

  if (file.size > maxSize) {
    return "File size exceeds the limit of 500KB.";
  }

  return "";
};

const ReportAdd = props => {
  const { id } = useParams();

  const initialValues = {
    name: "",
    partnerId: "",
    code: "",
    viewImage: "",
    remarks: "",
    status: "1",
    query: "",
    queryFilter: "",
    queryOrderBy: "",
    forPartner: false,
    columns: [
      {
        keyName: "",
        keyValue: "",
      },
    ],
  };
  const [formData, setFormData] = useState({ ...initialValues });
  const [isOpen, setIsOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [editColProp, setEditColProp] = useState([]);
  const [keyValues, setKeyValues] = useState([]);
  const [userTokenDel, setUserTokenDel] = useState([]);
  const [partnerOption, setPartnerOption] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  Yup.addMethod(Yup.array, "unique", function (field, message) {
    return this.test("unique", message, function (array) {
      const uniqueData = Array.from(
        new Set(array.map(row => row[field]?.toLowerCase()))
      );
      const isUnique = array.length === uniqueData.length;
      if (isUnique) {
        return true;
      }
      const index = array.findIndex(
        (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
      );

      if (index == -1) {
        return true;
      }
      if (array[index][field] === "") {
        return true;
      }

      return this.createError({
        path: `${this.path}.${index}.${field}`,
        message,
      });
    });
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Name should be 3-50 only characters")
      .max(50, "Name should be 3-50 only characters")
      .required("Please Enter Name"),
    code: Yup.string().min(2).max(100).required("Please enter Unique code"),
    partnerId: Yup.string().when("forPartner", {
      is: value => {
        return value;
      },
      then: Yup.string().required("Please select a partner"),
    }),
    viewImage: Yup.mixed()
      .test("fileSize", "File size must be less than 500KB", value => {
        if (value && value.size) {
          return !value || (value && value.size <= maxSize);
        }
        return true;
      })
      .test("fileType", "Only PNG, JPEG, and JPG files are allowed", value => {
        if (value && value.type) {
          return supportedFormats.includes(value.type);
        }
        return true;
      }),
    status: Yup.string().required("Select Report status"),
    query: Yup.string()
      .required("Please Enter Report query")
      .min(10, "Query should be minimum 10 characters"),
    queryFilter: Yup.string()
      .required("Please Enter Report query Filter")
      .min(10, "Report query filter should be 10-3000 only characters")
      .max(3000, "Report query filter should be 10-3000 only characters"),
    queryOrderBy: Yup.string().max(
      3000,
      "Report query Order By should be 10-3000 only characters"
    ),
    remarks: Yup.string(),
    columns: Yup.array()
      .unique("keyName", "Please provide a unique key name.")
      .of(
        Yup.object().shape({
          keyName: Yup.string()
            .min(3, "Key Name should be 3-50 only characters")
            .max(50, "Key Name should be 3-50 only characters")
            .required("Please Enter Key Name"),
          keyValue: Yup.string()
            .min(3, "Key Value should be 3-50 only characters")
            .max(50, "Key Value should be 3-50 only characters")
            .required("Please Enter Key Value"),
        })
      ),
  });

  const handleChange = (e, setValues, values) => {
    const fd = JSON.parse(JSON.stringify(values));
    let name = e.target?.name || "";
    let value = e.target?.value || "";
    let index = e.target?.index || "";
    if (index) {
      fd["columns"][index][name] = value;
    } else {
      fd[name] = value;
    }

    setValues(fd);
  };

  useEffect(async () => {
    props.setLoading(true);
    setKeyValues(keyValueOption);
    let authUser = localStorage.getItem("authUser");
    authUser = authUser ? JSON.parse(authUser) : {};

    if (authUser) {
      let authToken = authUser?.authToken || "";
      let partner = authUser?.partner || "";
      if (authToken) {
        const userDetails = parseJwt(authToken);
        setUserTokenDel(userDetails);
      }
      if (partner) {
        setPartnerOption([partner]);
      }
    }
    props.setLoading(false);
  }, []);

  useEffect(async () => {
    if (id) {
      props.setLoading(true);
      try {
        const response = await getReportAction(id);
        if (response.status === 1) {
          const {
            name,
            partnerId,
            viewImage,
            remarks,
            status,
            forPartner = partnerId ? true : false,
            columns,
            query,
            queryFilter,
            uniqueCode,
            queryOrderBy,
          } = response.data;
          setFormData({
            name,
            partnerId: partnerId || "",
            viewImage,
            remarks,
            query,
            queryOrderBy,
            queryFilter,
            code: uniqueCode,
            status: `${status}`,
            forPartner,
            columns: JSON.parse(columns),
          });

          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      } catch (error) {
        props.setLoading(false);
        console.log(error);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [id]);

  useEffect(async () => {
    if (!userTokenDel.masterId) {
      const response2 = await getPartnerAction();
      if (response2.status === 1) {
        let arr = response2?.data || [];
        userTokenDel.masterId ? (arr = [arr]) : arr;
        if (!id) {
          arr = arr.filter(val => val.status === 1);
        }
        setPartnerOption(arr);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        // toast.error(response2.msg);
      }
    }
  }, [userTokenDel]);

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("viewImage", file); // Set the value of the "viewImage" field

    if (file) {
      setSelectedImage(URL.createObjectURL(file)); // Set the selected image for preview
    } else {
      setSelectedImage(null); // Clear the selected image preview
    }
  };

  const saveReport = async (data, action) => {
    try {
      props.setLoading(true);
      const response = await saveReportAction(
        { ...data, formId: getFormId() },
        id
      );
      if (response.status === 1) {
        props.setLoading(false);
        // if (id) {
        //   history.push("/panel/partner/list");
        // }
        toast.success(response.msg);
        action.resetForm();
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  const handleSubmit = async (values, action) => {
    try {
      let data = {};
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result;
        // Send form data and base64Image to the server
        saveReport({ ...values, image: base64Image }, action);
      };

      if (values.viewImage) {
        reader.readAsDataURL(values.viewImage);
      } else {
        saveReport({ ...values }, action);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleColumnChange = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteCol = columns => {
    setColumns(prev => {
      return prev.filter((col, i) => {
        return (
          col.headerName != columns.headerName || col.field != columns.field
        );
      });
    });
  };
  const handleEditCol = col => {
    console.log(col);
    setEditColProp(col);
    setIsOpen(true);
  };
  return (
    <div className="page-content mb-4">
      <Container>
        {id ? (
          <Breadcrumbs
            title="Report Types"
            breadcrumbItem={"Edit Partner"}
            path="/panel/Report/list"
          />
        ) : (
          ""
        )}

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(val, acc) => handleSubmit(val, acc)}
                >
                  {({
                    errors,
                    touched,
                    resetForm,
                    values,
                    setValues,
                    setFieldValue,
                  }) => (
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="name">
                              View Name <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Enter View Name"
                              maxLength={50}
                              className={"form-control"}
                            />
                            <span className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name="name"
                              />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <div className="mt-4">
                            <h5 className="font-size-14 mb-1">
                              Status <span className=" text-danger">*</span>
                            </h5>
                            <div className="d-flex">
                              <div className="form-check me-2">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="active"
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="active"
                                >
                                  active
                                </label>
                              </div>
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inactive"
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inactive"
                                >
                                  inactive
                                </label>
                              </div>
                            </div>
                          </div>
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="status"
                            />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        {!userTokenDel.masterId && (
                          <>
                            <Col md={6}>
                              <div className="d-flex my-4">
                                <Field
                                  type="checkbox"
                                  name="forPartner"
                                  id="forPartner"
                                  label="Check the mark"
                                />
                                <label
                                  className="form-check-label ms-2 fw-bold"
                                  htmlFor="forPartner"
                                >
                                  For partner only
                                </label>
                              </div>
                            </Col>
                            {values.forPartner && (
                              <Col md="6">
                                <FormGroup>
                                  <Label for="partnerId">
                                    Partner
                                    <span className=" text-danger"> *</span>
                                  </Label>
                                  <Select
                                    name="partnerId"
                                    id="partnerId"
                                    placeholder="Select Partner Name"
                                    value={
                                      id &&
                                      partnerOption.filter(
                                        obj => obj.id === formData.partnerId
                                      )[0]
                                    }
                                    getOptionLabel={e => e.companyName}
                                    getOptionValue={e => e.id}
                                    onChange={e =>
                                      handleChange(
                                        {
                                          target: {
                                            name: "partnerId",
                                            value: e.id,
                                          },
                                        },
                                        setValues,
                                        values
                                      )
                                    }
                                    options={partnerOption}
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name="partnerId"
                                    />
                                  </span>
                                </FormGroup>
                              </Col>
                            )}
                            <Col md="6">
                              <FormGroup>
                                <Label for="code">
                                  Unique Code{" "}
                                  <span className=" text-danger">*</span>
                                </Label>
                                <Field
                                  type="text"
                                  name="code"
                                  id="code"
                                  placeholder="Enter Unique Code"
                                  maxLength={50}
                                  className={"form-control"}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="code"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                          </>
                        )}
                        <Col md="6">
                          <FormGroup>
                            <Label htmlFor="viewImage">View Image</Label>
                            <Field
                              type="file"
                              id="viewImage"
                              name="viewImage"
                              className={"form-control"}
                              onChange={event =>
                                handleFileChange(event, setFieldValue)
                              }
                              value={undefined}
                            />
                            <ErrorMessage name="viewImage">
                              {msg => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="query">Report Query</Label>
                            <Field
                              as="textarea"
                              name="query"
                              placeholder="Enter Report Query"
                              id="query"
                              rows="3"
                              className="form-control"
                            />
                          </FormGroup>
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="query"
                            />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="queryFilter">Report Query Filter</Label>
                            <Field
                              as="textarea"
                              name="queryFilter"
                              placeholder="Enter Report Query Filter"
                              id="queryFilter"
                              rows="3"
                              className="form-control"
                            />
                          </FormGroup>
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="queryFilter"
                            />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="queryOrderBy">Order By</Label>
                            <Field
                              as="textarea"
                              name="queryOrderBy"
                              placeholder="Enter Order By"
                              id="queryOrderBy"
                              rows="5"
                              maxLength={3000}
                              className="form-control"
                            />
                          </FormGroup>
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name="queryOrderBy"
                            />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="remarks">Remark</Label>
                            <Field
                              as="textarea"
                              name="remarks"
                              placeholder="remarks"
                              id="remarks"
                              rows="5"
                              maxLength={300}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <fieldset className="border border-3 p-3">
                        <legend className="float-none w-auto px-2">Keys</legend>
                        <FieldArray
                          name="columns"
                          render={arrayHelper => {
                            const columns = values.columns;
                            return (
                              <div>
                                {columns && columns.length > 0
                                  ? columns.map((keys, i) => (
                                      <Row key={i}>
                                        <Col md="6">
                                          <FormGroup>
                                            <Label for={`columns.${i}.keyName`}>
                                              Key Name{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </Label>
                                            <Field
                                              type="text"
                                              name={`columns.${i}.keyName`}
                                              id={`columns.${i}.keyName`}
                                              placeholder="Enter Key Name"
                                              maxLength={50}
                                              className={"form-control"}
                                              value={keys.keyName}
                                            />
                                            <span className="text-danger">
                                              <ErrorMessage
                                                className="text-danger"
                                                name={`columns.${i}.keyName`}
                                              />
                                            </span>
                                          </FormGroup>
                                        </Col>
                                        <Col md="6">
                                          <FormGroup>
                                            <Label
                                              for={`columns.${i}.keyValue`}
                                            >
                                              Key Value
                                              <span className=" text-danger">
                                                {" "}
                                                *
                                              </span>
                                            </Label>
                                            <Select
                                              name={`columns.${i}.keyValue`}
                                              id={`columns.${i}.keyValue`}
                                              placeholder="Select Key type"
                                              onChange={e =>
                                                handleChange(
                                                  {
                                                    target: {
                                                      name: `keyValue`,
                                                      index: `${i}`,
                                                      value: e.value,
                                                    },
                                                  },
                                                  setValues,
                                                  values
                                                )
                                              }
                                              value={keyValueOption.find(
                                                obj =>
                                                  obj.value === keys.keyValue
                                              )}
                                              options={keyValues}
                                            />
                                            <span className="text-danger">
                                              <ErrorMessage
                                                className="text-danger"
                                                name={`columns.${i}.keyValue`}
                                              />
                                            </span>
                                          </FormGroup>
                                        </Col>
                                        <div className="d-flex mb-3">
                                          <button
                                            className={`btn btn-danger ms-auto ${
                                              columns.length == 1
                                                ? "disabled"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              arrayHelper.remove(i)
                                            }
                                            type="button"
                                          >
                                            Remove Key
                                          </button>
                                        </div>
                                      </Row>
                                    ))
                                  : null}
                                <button
                                  className="btn btn-success"
                                  onClick={() =>
                                    arrayHelper.push({
                                      keyName: "",
                                      keyValue: "",
                                    })
                                  }
                                  type="button"
                                >
                                  Add Key
                                </button>
                              </div>
                            );
                          }}
                        />
                      </fieldset>

                      <fieldset className="border border-3 p-3">
                        <legend className="float-none w-auto px-2">
                          Pivot Property
                        </legend>

                        <Label className="fs-4">Column</Label>

                        <Row>
                          {columns.map((item, index) => (
                            <Col
                              key={index}
                              md={6}
                              className="border border-3 p-2 d-flex align-items-center justify-content-between"
                            >
                              <div className="details">
                                <div className="">
                                  <span className="fw-bold">
                                    Header Name :{" "}
                                  </span>
                                  <span> {item.headerName}</span>
                                </div>
                                <div className="">
                                  <span className="fw-bold">Field : </span>
                                  <span> {item.field}</span>
                                </div>
                              </div>
                              <div className="btn-container">
                                <div
                                  className="btn btn-primary"
                                  onClick={() => handleDeleteCol(item)}
                                >
                                  <span className="material-symbols-outlined">
                                    delete
                                  </span>
                                </div>
                                <div
                                  className="btn btn-danger ms-3"
                                  onClick={() => handleEditCol(item)}
                                >
                                  <span className="material-symbols-outlined">
                                    edit
                                  </span>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                        <AddColumnModal
                          isOpen={isOpen}
                          closeAction={handleColumnChange}
                          setColumns={setColumns}
                          columnProperties={editColProp}
                        />
                        <div className="mt-2">
                          <button
                            className="btn btn-success"
                            type="button"
                            onClick={handleColumnChange}
                          >
                            Add Column
                          </button>
                        </div>
                      </fieldset>
                      <Row>
                        <Col xs={{ size: 2, offset: 5 }}>
                          <Button
                            color="primary"
                            type="submit"
                            className="my-3"
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loader(ReportAdd);
