import Breadcrumbs from "components/Common/Breadcrumb";
import Loader from "components/Common/Loader";
import { ErrorMessage, Form, Field, Formik } from "formik";
import usePasswordToggle from "hooks/usePasswordToggle";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { changePassAction } from "store/actions";
import * as Yup from "yup";

const ChangePassword = props => {
  const [passIcon, passInputType] = usePasswordToggle();
  const [cIcon, CInputType] = usePasswordToggle();
  const [oldpassIcon, oldpassInputType] = usePasswordToggle();
  const history = useHistory();
  const initialValues = {
    oldpassword: "",
    password: "",
    cPassword: "",
  };
  const validationSchema = Yup.object({
    oldpassword: Yup.string()
      .min(6, "password minimum 6 characters contain")
      .required("Enter old password"),
    password: Yup.string()
      .min(6, "password minimum 6 characters contain")
      .required("Enter password"),
    cPassword: Yup.string()
      .required("Enter same password")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  const handleSubmit = async (values, action) => {
    try {
      props.setLoading(true);
      const response = await changePassAction(values);
      if (response.status === 1) {
        props.setLoading(false);
        toast.success(response.msg);
        localStorage.removeItem("authUser");
        localStorage.removeItem("profile");
        history.push("/");
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  return (
    <div className="page-content">
      <Container>
        <Breadcrumbs
          title="Dashboard"
          breadcrumbItem="Password Change"
          path="/panel/dashboard"
        />

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, resetForm, values }) => (
                    <Form>
                      <Row>
                        <FormGroup>
                          <Label for="oldpassword">
                            Old Password <span className=" text-danger">*</span>
                          </Label>
                          <Field
                            type={oldpassInputType}
                            name="oldpassword"
                            id="oldpassword"
                            placeholder="Enter old password"
                            maxLength={25}
                            className={
                              "form-control" +
                              (errors.oldpassword && touched.oldpassword
                                ? " is-invalid"
                                : "")
                            }
                          />
                          {values.oldpassword && (
                            <span className="password-toggle-icon">
                              {oldpassIcon}
                            </span>
                          )}
                          <ErrorMessage
                            name="oldpassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup>
                          <Label for="password">
                            Password <span className=" text-danger">*</span>
                          </Label>
                          <Field
                            type={passInputType}
                            name="password"
                            id="password"
                            placeholder="Enter password"
                            maxLength={25}
                            className={
                              "form-control" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          {values.password && (
                            <span className="password-toggle-icon">
                              {passIcon}
                            </span>
                          )}
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup>
                          <Label for="cPassword">
                            Confirm password
                            <span className=" text-danger"> *</span>
                          </Label>
                          <Field
                            type={CInputType}
                            name="cPassword"
                            id="cPassword"
                            placeholder="Confirm Your password"
                            maxLength={25}
                            className={
                              "form-control" +
                              (errors.cPassword && touched.cPassword
                                ? " is-invalid"
                                : "")
                            }
                          />
                          {values.cPassword && (
                            <span className="password-toggle-icon">
                              {cIcon}
                            </span>
                          )}
                          <ErrorMessage
                            name="cPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                      </Row>

                      <Row>
                        <Col xs={{ size: 2, offset: 5 }}>
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loader(ChangePassword);
