import Breadcrumbs from "components/Common/Breadcrumb";
import Loader from "components/Common/Loader";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, FormGroup, Row } from "reactstrap";
import { getGraphData, getPartnerAction } from "store/actions";
import Apaexlinecolumn from "../Graphs/apaexlinecolumn";
import Chartapex from "../Graphs/chartapex";
import SplineArea from "../Graphs/SplineArea";
import { toast } from "react-toastify";
import ColumnWithDataLabels from "../Graphs/ColumnWithDataLabels";
import { parseJwt } from "helpers/common_helper";

function DateManipulation(props) {
  const { setDD, updateDD, setMM, updateMM, setYY, updateYY, lastDay } = props;
  const initialDate = new Date();

  if (setMM !== undefined) {
    initialDate.setMonth(setMM);
  }
  if (updateMM) {
    initialDate.setMonth(initialDate.getMonth() + updateMM);
  }

  if (setDD) {
    initialDate.setDate(setDD);
  }
  if (updateDD) {
    initialDate.setDate(initialDate.getDate() + updateDD);
  }

  if (setYY) {
    console.log(setYY);
    initialDate.setFullYear(setYY);
  }
  if (updateYY) {
    initialDate.setFullYear(initialDate.getFullYear() + updateYY);
  }

  if (lastDay) {
    const year = initialDate.getFullYear();
    const month = initialDate.getMonth();
    const nextMonth = new Date(year, month + 1, 1);
    const lastDay = new Date(nextMonth - 1);
    initialDate.setDate(lastDay.getDate());
  }

  return initialDate.toISOString().split("T")[0];
}

function setCustomDate(filter, value, name, custom) {
  const date = new Date();
  const current_month = date.getMonth();

  let filterData = JSON.parse(JSON.stringify(filter));
  let fromDate = DateManipulation({
    updateDD: 0,
    updateMM: 0,
    updateYY: -1,
  });
  let toDate = DateManipulation({
    updateDD: 0,
    updateMM: 0,
    updateYY: 0,
  });

  if (value == "current_day") {
    fromDate = DateManipulation({
      updateDD: 0,
      updateMM: 0,
      updateYY: 0,
    });
    toDate = DateManipulation({
      updateDD: 0,
      updateMM: 0,
      updateYY: 0,
    });
  }
  if (value == "previous_day") {
    fromDate = DateManipulation({
      updateDD: -1,
      updateMM: 0,
      updateYY: 0,
    });
    toDate = DateManipulation({
      updateDD: -1,
      updateMM: 0,
      updateYY: 0,
    });
  }

  if (value == "last_7_days") {
    fromDate = DateManipulation({
      updateDD: -7,
      updateMM: 0,
      updateYY: 0,
    });
    toDate = DateManipulation({
      updateDD: 0,
      updateMM: 0,
      updateYY: 0,
    });
  }
  if (value == "last_15_days") {
    fromDate = DateManipulation({
      updateDD: -15,
      updateMM: 0,
      updateYY: 0,
    });
    toDate = DateManipulation({
      updateDD: 0,
      updateMM: 0,
      updateYY: 0,
    });
  }
  if (value == "last_n_days" || custom == "last_n_days") {
    fromDate = DateManipulation({
      updateDD: -parseInt(value),
      updateMM: 0,
      updateYY: 0,
    });
    toDate = DateManipulation({
      updateDD: 0,
      updateMM: 0,
      updateYY: 0,
    });
  }

  if (value == "current_month") {
    fromDate = DateManipulation({
      setDD: 1,
      updateMM: 0,
      updateYY: 0,
    });
    toDate = DateManipulation({
      updateDD: 0,
      updateMM: 0,
      updateYY: 0,
    });
  }
  if (value == "last_month") {
    fromDate = DateManipulation({
      setDD: 1,
      updateMM: -1,
      updateYY: 0,
    });

    toDate = DateManipulation({
      lastDay: true,
      updateMM: -1,
      updateYY: 0,
    });
  }
  if (value == "current_financial_year") {
    fromDate = DateManipulation({
      setDD: 1,
      setMM: 3,
      updateYY: current_month < 3 ? -1 : 0,
    });

    toDate = DateManipulation({
      updateDD: 0,
      updateMM: 0,
      updateYY: 0,
    });
  }
  if (value == "last_financial_year") {
    fromDate = DateManipulation({
      setDD: 1,
      setMM: 3,
      updateYY: current_month < 3 ? -2 : -1,
    });
    toDate = DateManipulation({
      setDD: 31,
      setMM: 2,
      updateYY: current_month < 3 ? -1 : 0,
    });
  }
  if (value == "last_calendar_year") {
    fromDate = DateManipulation({
      setDD: 1,
      setMM: 0,
      updateYY: -1,
    });

    toDate = DateManipulation({
      setDD: 31,
      setMM: 11,
      updateYY: -1,
    });
  }
  return {
    ...filterData,
    fromDate,
    toDate,
    [name]: value,
  };
}

const index = props => {
  const [graphData, setGraphData] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [visibleFilterContainers, setVisibleFilterContainers] = useState([]);
  const [filterGraphId, setFilterGraphId] = useState(null);
  const [userTokenDel, setUserTokenDel] = useState({});
  const [selectedPartner, setSelectedPartner] = useState("");
  const [partnerOption, setPartnerOption] = useState([]);

  useEffect(async () => {
    try {
      let authUser = localStorage.getItem("authUser");
      authUser = authUser ? JSON.parse(authUser) : {};

      if (authUser) {
        let authToken = authUser?.authToken || "";
        if (authToken) {
          const userDetails = parseJwt(authToken);
          setUserTokenDel(userDetails);
          if (!userDetails.masterId) {
            props.setLoading(true);
            const response = await getPartnerAction();
            if (response.status === 1) {
              let arr = response?.data || [];
              userTokenDel.masterId ? (arr = [arr]) : arr;
              setSelectedPartner(arr[0].id);
              console.log(arr);
              setPartnerOption(arr);
            } else {
              // toast.error(response2.msg);
            }
          } else {
            setSelectedPartner(userDetails.masterId);
            setValues({
              ...values,
              partner: userDetails.masterId,
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  useEffect(async () => {
    if (selectedPartner) {
      try {
        props.setLoading(true);
        const response = await getGraphData({
          type: "all",
          masterId: selectedPartner,
        });
        if (response.status === 1) {
          setGraphData(response.data);
          const initialFilters = response.data.map(item => ({
            id: item.id,
            fromDate: item.fromDt,
            toDate: item.toDt,
            orderByList: item.orderBy,
            order: item.order,
            masterId: item.masterId,
          }));
          setFilterList(initialFilters);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      } catch (error) {
        props.setLoading(false);
        console.log(error);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [selectedPartner]);

  const handleFilter = async (e, id) => {
    const { value, name } = e.target;
    setFilterList(prevFilters => {
      const updatedFilters = prevFilters.map(filter => {
        if (filter.id === id) {
          if (name == "nDays") {
            return setCustomDate(filter, value, name, "last_n_days");
          }
          if (name == "custom") {
            return setCustomDate(filter, value, name);
          }
          if (name == "fromDate" || name == "toDate") {
            return {
              ...filter,
              [name]: value,
              custom: "",
            };
          }
          return { ...filter, [name]: value };
        } else {
          return filter;
        }
      });
      console.log("updatedFilters", updatedFilters);
      return updatedFilters;
    });
    setFilterGraphId(id);
  };

  useEffect(async () => {
    try {
      if (filterGraphId && filterList) {
        let filterData = filterList.filter(
          filter => filter.id === filterGraphId
        );
        if (filterData[0].custom !== "last_n_days" || filterData[0].nDays) {
          props.setLoading(true);
          const response = await getGraphData({
            ...filterData[0],
            type: filterGraphId,
          });
          if (response.status === 1) {
            const filterGraphData = graphData.map(item => {
              if (item.id == filterGraphId) {
                return response.data[0];
              }
              return item;
            });

            setGraphData(filterGraphData);
            props.setLoading(false);
          } else {
            props.setLoading(false);
            toast.error(response.msg);
          }
        }
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [filterGraphId, filterList]);

  const toggleFilterContainer = itemId => {
    setVisibleFilterContainers(prevState => {
      if (prevState.includes(itemId)) {
        return prevState.filter(id => id !== itemId);
      } else {
        return [...prevState, itemId];
      }
    });
  };

  const isFilterContainerVisible = itemId => {
    return visibleFilterContainers.includes(itemId);
  };

  const handlePartnerChange = e => {
    setSelectedPartner(e.target.value);
  };
  return (
    <div className="page-content">
      <MetaTags>
        <title>All Sales Graph</title>
      </MetaTags>
      <div className="container-fluid">
        {/* <Breadcrumbs title="Sales Graph" breadcrumbItem="All Sales Graph" /> */}
        <Row>
          {!userTokenDel.masterId && (
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col md="6">
                    <FormGroup>
                      <label htmlFor="partner">
                        Select Partner
                        <span className=" text-danger"> *</span>
                      </label>
                      <select
                        name="partner"
                        id="partner"
                        className={"form-select"}
                        value={selectedPartner}
                        onChange={e => handlePartnerChange(e)}
                      >
                        <option value={""}>Select partner</option>
                        {partnerOption.map(value => (
                          <option value={value.id} key={value.id}>
                            {value.companyName}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          )}
          {graphData.map((item, i) => (
            <Col lg={6} key={i}>
              <Card>
                <CardBody>
                  <div className="filter-btn-container">
                    <button
                      className="btn btn-primary btn-sm filter-btn"
                      onClick={() => toggleFilterContainer(item.id)}
                      aria-expanded={isFilterContainerVisible(item.id)}
                    >
                      {isFilterContainerVisible(item.id) ? (
                        <span className="material-icons-outlined btn-icon">
                          close
                        </span>
                      ) : (
                        <span className="material-icons-outlined btn-icon">
                          filter_alt
                        </span>
                      )}
                    </button>
                  </div>
                  <div
                    className={`filter-container border p-3 row mb-3 collapse ${
                      isFilterContainerVisible(item.id) ? "show" : ""
                    }`}
                  >
                    <div className="form-group col-4">
                      <label htmlFor={`fromDate_${item.id}`} className="mb-1">
                        From Date:
                      </label>
                      <input
                        type="date"
                        className="form-control mb-1"
                        name="fromDate"
                        id={`fromDate_${item.id}`}
                        value={
                          filterList.find(filter => filter.id === item.id)
                            ?.fromDate || item.fromDt
                        }
                        onChange={e => handleFilter(e, item.id)}
                      />
                    </div>
                    <div className="form-group col-4">
                      <label htmlFor={`toDate_${item.id}`} className="mb-1">
                        To Date:
                      </label>
                      <input
                        type="date"
                        name="toDate"
                        className="form-control mb-1"
                        value={
                          filterList.find(filter => filter.id === item.id)
                            ?.toDate || item.toDt
                        }
                        id={`toDate_${item.id}`}
                        onChange={e => handleFilter(e, item.id)}
                      />
                    </div>
                    <div className="form-group col-4">
                      <div className="row">
                        <div
                          className={`${
                            filterList.find(filter => filter.id === item.id)
                              ?.custom == "last_n_days"
                              ? "col-7"
                              : "col-12"
                          }`}
                        >
                          <label htmlFor={`custom_${item.id}`} className="mb-1">
                            Custom:
                          </label>
                          <select
                            name="custom"
                            className="form-select mb-1"
                            id={`custom_${item.id}`}
                            value={
                              filterList.find(filter => filter.id === item.id)
                                ?.custom || ""
                            }
                            onChange={e => handleFilter(e, item.id)}
                          >
                            <option value="">None</option>
                            <option value="current_day">Current Day</option>
                            <option value="previous_day">Previous Day</option>
                            <option value="last_7_days">Last 7 Days</option>
                            <option value="last_15_days">Last 15 Days</option>
                            <option value="last_n_days">Last n Days</option>
                            <option value="current_month">Current Month</option>
                            <option value="last_month">Last Month</option>
                            <option value="current_financial_year">
                              Current Financial Year
                            </option>
                            <option value="last_financial_year">
                              Last Financial Year
                            </option>
                            <option value="last_calendar_year">
                              Last Calendar Year
                            </option>
                          </select>
                        </div>
                        <div
                          className={`${
                            filterList.find(filter => filter.id === item.id)
                              ?.custom == "last_n_days"
                              ? "col-5"
                              : "d-none"
                          }`}
                        >
                          <label htmlFor={`nDays_${item.id}`} className="mb-1">
                            Days:
                          </label>
                          <input
                            name="nDays"
                            type="text"
                            className="form-control mb-1"
                            id={`nDays_${item.id}`}
                            value={
                              filterList.find(filter => filter.id === item.id)
                                ?.nDays || ""
                            }
                            onChange={e => handleFilter(e, item.id)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-4">
                      <label
                        htmlFor={`orderByList_${item.id}`}
                        className="mb-1"
                      >
                        Order By List:
                      </label>
                      <select
                        name="orderByList"
                        className="form-select mb-1"
                        id={`orderByList_${item.id}`}
                        value={
                          filterList.find(filter => filter.id === item.id)
                            ?.orderByList || item.orderBy
                        }
                        onChange={e => handleFilter(e, item.id)}
                      >
                        {item.orderByList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-4">
                      <label htmlFor={`order_${item.id}`} className="mb-1">
                        Order:
                      </label>
                      <select
                        name="order"
                        className="mb-1 form-select"
                        id={`order_${item.id}`}
                        value={
                          filterList.find(filter => filter.id === item.id)
                            ?.order || item.order
                        }
                        onChange={e => handleFilter(e, item.id)}
                      >
                        <option value="desc">Fast Moving</option>
                        <option value="asc">Slow Moving</option>
                      </select>
                    </div>
                    <div className="form-group col-4">
                      <label htmlFor={`top${item.id}`} className="mb-1">
                        Top :
                      </label>
                      <select
                        name="top"
                        className="form-select mb-1"
                        id={`top${item.id}`}
                        value={
                          filterList.find(filter => filter.id === item.id)
                            ?.top || item.top
                        }
                        onChange={e => handleFilter(e, item.id)}
                      >
                        {item.topList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <CardTitle className="mb-4">{item.name}</CardTitle>

                  {item.id === "001" && <Apaexlinecolumn data={item.data} />}
                  {item.id === "002" && <Chartapex data={item.data} />}
                  {item.id === "003" && <SplineArea data={item.data} />}
                  {item.id === "004" && (
                    <ColumnWithDataLabels data={item.data} />
                  )}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Loader(index);
