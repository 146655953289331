import Breadcrumbs from "components/Common/Breadcrumb";
import Loader from "components/Common/Loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { getFormId } from "helpers/common_helper";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import {
  getCityListAction,
  getCountryListAction,
  getStateListAction,
  saveCityAction,
} from "store/actions";
import * as Yup from "yup";

const CityAdd = props => {
  const { id } = useParams();
  const history = useHistory();
  const initialValues = {
    name: "",
    code: "",
    status: "1",
    state: "",
    country: "",
  };
  const [values, setValues] = useState(initialValues);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [selectState, setSelectState] = useState([]);
  const validationSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter city name"),
    code: Yup.string().min(2).max(100).required("Please enter city code"),
    state: Yup.string().required("Please Select State"),
    country: Yup.string().required("Please Select Country"),
  });
  const handleSubmit = async (values, action) => {
    try {
      props.setLoading(true);
      let response = await saveCityAction(
        {
          ...values,
          formId: getFormId(),
        },
        id
      );
      if (response.status === 1) {
        props.setLoading(false);
        toast.success(response.msg);
        if (id) {
          history.push("/panel/city/list");
        }
        action.resetForm();
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  useEffect(async () => {
    try {
      props.setLoading(true);
      const country = await getCountryListAction();
      const state = await getStateListAction();
      if (country.status === 1 || state.status == 1) {
        let data = country?.data || [];
        setCountry(data);
        data = state?.data || [];
        setState(data);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, []);

  useEffect(async () => {
    try {
      if (id) {
        props.setLoading(true);
        const response = await getCityListAction(id);
        if (response.status === 1) {
          let data = response?.data || [];
          if (data) {
            const country = await getCountryListAction();
            const state = await getStateListAction();
            let selectState = state.data.filter(val => val.id == data.underId);

            let selectCountry = country.data.filter(
              val => val.id == selectState[0].underId
            );
            setSelectState(
              state.data.filter(val => val.underId == selectCountry[0].id)
            );
            setValues({
              name: data.name,
              code: data.code,
              status: `${data.status}`,
              state: data.underId,
              country: selectCountry[0].id,
            });
            props.setLoading(false);
          }
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [id]);

  const handleChange = (e, setValues, values) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    const relativeState = state.filter(val => val.underId == value);
    setSelectState(relativeState);
  };
  return (
    <div className="page-content mb-4">
      <Container>
        {id && (
          <Breadcrumbs
            title="City"
            breadcrumbItem={"City Edit"}
            path="/panel/city/list"
          />
        )}

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={values}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(val, acc) => handleSubmit(val, acc)}
                >
                  {({ errors, touched, resetForm, setValues, values }) => (
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="name">
                              City name <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Enter City name"
                              maxLength={100}
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="code">
                              City code <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="code"
                              id="code"
                              placeholder="Enter City code"
                              maxLength={100}
                              className={
                                "form-control" +
                                (errors.code && touched.code
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="code"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="country">
                              Select country
                              <span className=" text-danger"> *</span>
                            </Label>
                            <Field
                              as="select"
                              name="country"
                              id="country"
                              onChange={e => handleChange(e, setValues, values)}
                              className={
                                "form-select" +
                                (errors.country && touched.country
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value={""}>Select country</option>
                              {country.map(value => (
                                <option value={value.id} key={value.id}>
                                  {value.name}
                                </option>
                              ))}
                            </Field>
                            {errors.country && touched.country ? (
                              <p className="text-danger mt-1">
                                {errors.country}
                              </p>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="state">
                              Select state
                              <span className=" text-danger"> *</span>
                            </Label>
                            <Field
                              as="select"
                              name="state"
                              id="state"
                              className={
                                "form-select" +
                                (errors.state && touched.state
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value={""}>Select state</option>
                              {selectState.map(value => (
                                <option value={value.id} key={value.id}>
                                  {value.name}
                                </option>
                              ))}
                            </Field>
                            {errors.state && touched.state ? (
                              <p className="text-danger mt-1">{errors.state}</p>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="mt-4">
                            <h5 className="font-size-14 mb-1">
                              Status<span className=" text-danger"> *</span>
                            </h5>
                            <div className="d-flex">
                              <div className="form-check me-2">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="active"
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="active"
                                >
                                  Active
                                </label>
                              </div>
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inactive"
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inactive"
                                >
                                  Inactive
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ size: 2, offset: 5 }}>
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loader(CityAdd);
