import { toast } from "react-toastify";
import TableView from "../../components/Common/TableView";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { getStateListAction } from "store/actions";
import Loader from "components/Common/Loader";
import { getFormId, urlValidation } from "helpers/common_helper";
import useFilter from "components/Common/useFilter";

const StateList = props => {
  const [products, setProducts] = useState([]);
  const formId = getFormId();
  const auth = urlValidation(formId);
  const [filter, handelFilter] = useFilter(6);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });

  const history = useHistory();
  useEffect(async () => {
    try {
      props.setLoading(true);
      const params = new URLSearchParams();

      Object.entries(filter).forEach(([key, value]) => {
        params.append(key, JSON.stringify(value));
      });

      const queryString = params.toString();
      const encodedQueryString = btoa(queryString);

      const response = await getStateListAction("", encodedQueryString);
      if (response.status === 1) {
        setServerPagination({ ...response.metadata });
        setProducts(response.data);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [filter]);

  const tableButton = {
    text: "Add State",
    path: "state/add",
  };
  const handleEdit = row => {
    history.push(`/panel/state/add/${row.id}`);
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") return rowA.sequence - rowB.sequence;
        else return rowB.sequence - rowA.sequence;
      },
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "State",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "createdAt",
      text: "Created",
      sort: true,
      formatter: data => {
        return data.slice(0, 10).split("-").reverse().join("/");
      },
    },
    {
      dataField: "updatedAt",
      text: "Updated",
      sort: true,
      formatter: data => {
        return data.slice(0, 10).split("-").reverse().join("/");
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: data => {
        return data === 1 ? (
          <span className=" badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger btn-rounded">Inactive</span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>State List | Report</title>
        </MetaTags>
        <TableView
          tableName="State List"
          columns={columns}
          products={products}
          filterData={{ filter, handelFilter }}
          Pagination={serverPagination}
          sortDate={{ form: filter.from, to: filter.to }}
          editAction={auth.allowEdit ? handleEdit : ""}
          buttonAdd={auth.allowCreate ? tableButton : ""}
        />
      </div>
    </React.Fragment>
  );
};

export default Loader(StateList);
