import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import useFilter from "components/Common/useFilter";
import {
  getPartnerAction,
  getPivotReportData,
  getVoucherType,
} from "store/actions";
import Loader from "components/Common/Loader";
import { toast } from "react-toastify";
import { decrypt, parseJwt } from "helpers/common_helper";
import AgGrid from "components/Common/AgGrid";
import Select from "react-select";

const Index = props => {
  const [filter, handelFilter] = useFilter(1);
  const [voucherTypeOption, setVoucherTypeOption] = useState({});
  const [selectVoucherType, setSelectVoucherType] = useState("");
  const [userTokenDel, setUserTokenDel] = useState({});
  const [selectedPartner, setSelectedPartner] = useState("");
  const [partnerOption, setPartnerOption] = useState([]);
  const [products, setProducts] = useState({});

  useEffect(async () => {
    try {
      props.setLoading(true);
      let authUser = localStorage.getItem("authUser");
      authUser = authUser ? JSON.parse(authUser) : {};

      if (authUser) {
        let authToken = authUser?.authToken || "";
        if (authToken) {
          const userDetails = parseJwt(authToken);
          if (!userDetails.masterId) {
            props.setLoading(true);
            const response = await getPartnerAction();
            if (response.status === 1) {
              let arr = response?.data || [];
              setPartnerOption(arr);
              setSelectedPartner(arr[0].id);
              props.setLoading(false);
            } else {
              props.setLoading(false);
              // toast.error(response2.msg);
            }
          }
          setUserTokenDel(userDetails);
        }
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  useEffect(async () => {
    if (Object.keys(userTokenDel).length > 0) {
      try {
        const VoucherType = await getVoucherType(selectedPartner);
        if (VoucherType.status === 1) {
          setVoucherTypeOption(VoucherType.data);
          if (VoucherType.data.length > 0) {
            setSelectVoucherType(VoucherType.data[0]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedPartner, userTokenDel]);

  useEffect(async () => {
    if (Object.keys(userTokenDel).length > 0 && selectVoucherType) {
      props.setLoading(true);
      try {
        const encodedQueryString = decrypt({
          ...filter,
        });
        const response = await getPivotReportData(
          selectedPartner,
          selectVoucherType.VoucherType,
          encodedQueryString
        );
        if (response.status === 1) {
          setProducts(response.data);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      } catch (error) {
        props.setLoading(false);
        console.log(error);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [filter, selectedPartner, userTokenDel, selectVoucherType]);

  const handlePartnerChange = partnerId => {
    props.setLoading(true);
    handelFilter({ ...filter, pagination: { ...filter.pagination, page: 1 } });
    setSelectedPartner(partnerId);
    props.setLoading(false);
  };
  const handleVocherTypeChange = voucherTypeId => {
    setSelectVoucherType(voucherTypeId);
  };
  const filterComponent = () => {
    return (
      <>
        <Col sm="6" md="4">
          <div className="row py-1">
            <div className="col-3 mt-auto mb-auto pe-0">
              <Label for="IntVoucherType">Voucher Type</Label>
            </div>
            <div className="col-9">
              <Select
                name="IntVoucherType"
                id="IntVoucherType"
                placeholder="Select IntVoucherType"
                getOptionLabel={e => e.VchTypeGuid}
                getOptionValue={e => e.VoucherType}
                onChange={e => handleVocherTypeChange(e)}
                value={selectVoucherType}
                options={voucherTypeOption}
              />
            </div>
          </div>
        </Col>
      </>
    );
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>All Charts</title>
      </MetaTags>
      <div className="container-fluid">
        <Breadcrumbs title="Charts" breadcrumbItem="All Charts" />
        <AgGrid
          tableName="Pivot View"
          filterData={{ filter, handelFilter }}
          sortDate={{ form: filter.from, to: filter.to }}
          partner={
            !userTokenDel.masterId && {
              option: partnerOption,
              handleChange: handlePartnerChange,
              defaultPartner: selectedPartner,
            }
          }
          filterComponent={filterComponent}
          dataSet={products}
        />
      </div>
    </div>
  );
};

export default Loader(Index);
