import React from "react";
import ReactApexChart from "react-apexcharts";

const ColumnWithDataLabels = props => {
  const seriesData = [
    {
      type: "bar",
      name: "Qty",
      data: props.data.series[0] && props.data.series[0].data,
    },
    {
      type: "line",
      name: "Amount",
      yAxisIndex: 1,
      data: props.data.series[1] && props.data.series[1].data,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: props.data.labels,
    },
    fill: {
      opacity: 1,
    },
    yaxis: [
      {
        title: {
          text: "Quantity",
        },
      },
      props.data.series[1]
        ? {
            opposite: true,
            title: {
              text: "Amount",
            },
          }
        : {},
    ],
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={seriesData}
        type="line"
        height={350}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default ColumnWithDataLabels;
