import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./hooks.css";

const usePasswordToggle = () => {
  const [visibility, setVisibility] = useState(false);
  const Icon = visibility ? (
    <span onClick={() => setVisibility(!visibility)}>
      <AiOutlineEye />
    </span>
  ) : (
    <span onClick={() => setVisibility(!visibility)}>
      <AiOutlineEyeInvisible />
    </span>
  );
  const inputType = visibility ? "text" : "password";

  return [Icon, inputType];
};

export default usePasswordToggle;
