import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
} from "reactstrap";

//import action
import {
  getChartsData,
  getPartnerAction,
  getSummaryAction,
} from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { toast } from "react-toastify";
import Loader from "components/Common/Loader";
import DataGraph from "./DataGraph";
import { parseJwt } from "helpers/common_helper";

const Dashboard = props => {
  const [record, setRecord] = useState({});
  const [cardData, setCardData] = useState([]);
  const [userType, setUserType] = useState(false);
  const [adminUser, setAdminUser] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [partnerOption, setPartnerOption] = useState([]);

  useEffect(async () => {
    try {
      if (selectedPartner) {
        props.setLoading(true);
        const response = await getSummaryAction(selectedPartner);
        if (response.status === 1) {
          props.setLoading(false);
          let obj = response?.data || {};
          setRecord(obj.graph);
          setCardData(obj.timeScheduleSales);
        } else {
          // toast.error(response.msg);
          props.setLoading(false);
        }
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      // toast.error(err);
    }
  }, [selectedPartner]);

  useEffect(async () => {
    try {
      let authUser = localStorage.getItem("authUser");
      authUser = authUser ? JSON.parse(authUser) : {};

      if (authUser) {
        let authToken = authUser?.authToken || "";
        if (authToken) {
          const userDetails = parseJwt(authToken);
          if (
            userDetails?.userType == "Partner-User" ||
            userDetails?.userType == "Admin"
          ) {
            setUserType(true);
          }
          setAdminUser(userDetails?.userType == "Admin");
          if (!userDetails.masterId) {
            props.setLoading(true);
            const response = await getPartnerAction();
            if (response.status === 1) {
              let arr = response?.data || [];
              setSelectedPartner(arr[0].id);
              setPartnerOption(arr);
            } else {
              // toast.error(response2.msg);
            }
          } else {
            setSelectedPartner(userDetails.masterId);
          }
        }
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  const handlePartnerChange = e => {
    setSelectedPartner(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Report</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Dashboards"}
            breadcrumbItem={"Dashboard"}
            path={"/panel/dashboard"}
          />
          {adminUser && (
            <Row>
              <Card>
                <CardBody>
                  <Col lg={6} className="offset-6">
                    <FormGroup>
                      <label htmlFor="partner">
                        Select Partner
                        <span className=" text-danger"> *</span>
                      </label>
                      <select
                        name="partner"
                        id="partner"
                        className={"form-select"}
                        value={selectedPartner}
                        onChange={e => handlePartnerChange(e)}
                      >
                        <option value={""}>Select partner</option>
                        {partnerOption.map(value => (
                          <option value={value.id} key={value.id}>
                            {value.companyName}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </CardBody>
              </Card>
            </Row>
          )}
          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>

            <Col xl="8">
              <Row>
                {cardData.map(item => {
                  const { Type, Quantity, Amount } = item;
                  if (Type == 1) {
                    return (
                      <Col md="4" key={Type}>
                        <Card className="mini-stats-wid mb-3">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="h5">Today Sales</p>
                                <div className="mb-0 text-muted fw-medium">
                                  <div>
                                    {Quantity >= 0
                                      ? `Quantity : ${Quantity}`
                                      : ""}
                                  </div>
                                  <div>
                                    {Amount >= 0 ? `Amount : ${Amount}` : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                  if (Type == 2) {
                    return (
                      <Col md="4" key={Type}>
                        <Card className="mini-stats-wid mb-3">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="h5">Yesterday Sales</p>
                                <div className="mb-0 text-muted fw-medium">
                                  <div>
                                    {Quantity >= 0
                                      ? `Quantity : ${Quantity}`
                                      : ""}
                                  </div>
                                  <div>
                                    {Amount >= 0 ? `Amount : ${Amount}` : ""}
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                  if (Type == 3) {
                    return (
                      <Col md="4" key={Type}>
                        <Card className="mini-stats-wid mb-3">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="h5">Current Week Sales</p>
                                <div className="mb-0 text-muted fw-medium">
                                  <div>
                                    {Quantity >= 0
                                      ? `Quantity : ${Quantity}`
                                      : ""}
                                  </div>
                                  <div>
                                    {Amount >= 0 ? `Amount : ${Amount}` : ""}
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                  if (Type == 4) {
                    return (
                      <Col md="4" key={Type}>
                        <Card className="mini-stats-wid mb-3">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="h5">Last Week Sales</p>
                                <div className="mb-0 text-muted fw-medium">
                                  <div>
                                    {Quantity >= 0
                                      ? `Quantity : ${Quantity}`
                                      : ""}
                                  </div>
                                  <div>
                                    {Amount >= 0 ? `Amount : ${Amount}` : ""}
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                  if (Type == 6) {
                    return (
                      <Col md="4" key={Type}>
                        <Card className="mini-stats-wid mb-3">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="h5">Today Sales Count</p>
                                <div className="mb-0 text-muted fw-medium">
                                  <div>
                                    {Quantity >= 0
                                      ? `Quantity : ${Quantity}`
                                      : ""}
                                  </div>
                                  <div>
                                    {Amount >= 0 ? `Amount : ${Amount}` : ""}
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                  if (Type == 7) {
                    return (
                      <Col md="4" key={Type}>
                        <Card className="mini-stats-wid mb-3">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="h5">Yesterday Sales Count</p>
                                <div className="mb-0 text-muted fw-medium">
                                  <div>
                                    {Quantity >= 0
                                      ? `Quantity : ${Quantity}`
                                      : ""}
                                  </div>
                                  <div>
                                    {Amount >= 0 ? `Amount : ${Amount}` : ""}
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                })}
                {/* <Col md="4">
                  <Card className="mini-stats-wid mb-3">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="h5">Total Record</p>
                          <h4 className="mb-0">
                            {record.totalClose +
                              record.totalPending +
                              record.totalLive +
                              record.totalHold}
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <span className="material-icons-outlined md-24">
                              summarize
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Row>
            {userType && (
              <Col xl="4">
                <MonthlyEarning id={selectedPartner} />
              </Col>
            )}
            <Col xl={`${userType ? 8 : 12}`}>
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title mb-4 h4">
                      Days-Wise Sales
                    </CardTitle>
                  </div>
                  <div className="clearfix" />
                  <DataGraph data={record} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Loader(Dashboard);
