import React from "react";
import ReactApexChart from "react-apexcharts";

const Chartapex = props => {
  const yaxisData = props.data.series.map(item => {
    if (item.name == "Quantity") {
      return {
        title: {
          text: "Quantity",
        },
        min: Math.min(...item.data),
        max: Math.max(...item.data),
      };
    }

    if (item.name == "Amount") {
      return {
        opposite: true,
        title: {
          text: "Amount",
        },
        min: Math.min(...item.data),
        max: Math.max(...item.data),
      };
    }
    return;
  });
  const options = {
    chart: { zoom: { enabled: false }, toolbar: { show: false } },
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1b44c", "#50a5f1"],
    dataLabels: { enabled: true },
    stroke: { width: [3, 3], curve: "straight" },
    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
      borderColor: "#f1f1f1",
    },
    markers: { style: "inverted", size: 3, hover: { size: 5 } },
    xaxis: {
      categories: props.data.labels,
      title: { text: "Division" },
    },
    yaxis: yaxisData,
    legend: {
      position: "bottom",
      horizontalAlign: "right",
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: { toolbar: { show: false } },
          legend: { show: false },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={props.data.series}
        type="line"
        height={410}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default Chartapex;
