import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useParams, useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import usePasswordToggle from "hooks/usePasswordToggle";
import { getFormId, parseJwt } from "helpers/common_helper";
import { toast } from "react-toastify";
import {
  getCityListAction,
  getClassificationAction,
  getCountryListAction,
  getPartnerAction,
  getStateListAction,
  getUgListAction,
  getUserAction,
  saveUserAction,
} from "store/actions";
import Loader from "components/Common/Loader";
import Select from "react-select";

const UserAdd = props => {
  const history = useHistory();
  const [passIcon, passInputType] = usePasswordToggle();
  const [cIcon, CInputType] = usePasswordToggle();
  const [userTokenDel, setUserTokenDel] = useState({});
  const { id } = useParams();
  const [userGroup, setUserGroup] = useState([]);
  const [userGroupOption, setUserGroupOption] = useState([]);
  const [partnerOption, setPartnerOption] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [class1Options, setClass1Options] = useState([]);
  const [class2Options, setClass2Options] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");

  const initialValues = {
    userType: "Partner-User",
    partner: "",
    fName: "",
    lName: "",
    email: "",
    mobileNo: "",
    password: "",
    cPassword: "",
    userGroup: "",
    status: "1",
    remarks: "",
    address1: "",
    address2: "",
    countryId: "",
    stateId: "",
    cityId: "",
    pinCode: "",
    class1: [],
    class2: [],
    appLogin: false,
  };
  const [values, setValues] = useState(initialValues);
  const validationSchema = Yup.object({
    fName: Yup.string().min(2).max(100).required("Please enter First name"),
    lName: Yup.string().min(2).max(100),
    email: Yup.string().email("Invalid email").required("Please Enter email"),
    mobileNo: Yup.string()
      .required("Please Enter mobile number")
      .phone("IN", false, "Invalid mobile"),
    password: !id
      ? Yup.string()
          .min(6, "password minimum 6 characters contain")
          .required("Enter password")
      : Yup.string(),
    cPassword: !id
      ? Yup.string()
          .required("Enter same password")
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      : Yup.string(),
    userGroup: Yup.string().required("select user group"),
    partner: Yup.string().when("userType", {
      is: value => {
        if (value === "Admin" || userTokenDel.masterId) {
          return false;
        } else {
          return true;
        }
      },
      then: Yup.string().required("Please select a partner"),
    }),
    class1: Yup.array().when("userType", {
      is: "Vender",
      then: Yup.array()
        .required("Please select Classification 1")
        .min(1, "Please select at least one Classification 1"),
    }),
    class2: Yup.array().when("userType", {
      is: "Vender",
      then: Yup.array()
        .required("Please select Classification 2")
        .min(1, "Please select at least one Classification 2"),
    }),
    address1: Yup.string().required("please Enter Address"),
    address2: Yup.string(),
    countryId: Yup.string().required("Please Select Country"),
    stateId: Yup.string().required("Please Select State"),
    cityId: Yup.string().required("Please Select City"),
    pinCode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, "Invalid pin code")
      .required("Please Enter pin code"),
  });

  useEffect(async () => {
    try {
      if (id) {
        props.setLoading(true);
        let data;
        const response = await getUserAction(id);
        if (response.status === 1) {
          data = response?.data || [];
          let class1 = JSON.parse(data.class1) || [];
          let class2 = JSON.parse(data.class2) || [];
          setValues({
            userType: `${data.userType ? data.userType : "Admin"}`,
            partner: data.masterId == null ? "" : data.masterId.toUpperCase(),
            fName: data.fName,
            lName: data.lName,
            email: data.email,
            mobileNo: data.mobileNo,
            userGroup: data.userGroupId,
            status: `${data.status}`,
            remarks: data.remarks,
            appLogin: data.appLogin === 1,
            address1: data.address1,
            address2: data.address2,
            class1: class1,
            class2: class2,
            countryId: data.countryId,
            stateId: data.stateId,
            cityId: data.cityId,
            pinCode: data.pinCode,
          });
          await getClassificationOption(data.masterId);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setStateOption(stateId.filter(val => val.underId == values.countryId));
      setCityOption(cityId.filter(val => val.underId == values.stateId));
    }
  }, [values, stateId, cityId]);

  useEffect(async () => {
    try {
      let authUser = localStorage.getItem("authUser");
      authUser = authUser ? JSON.parse(authUser) : {};

      if (authUser) {
        let authToken = authUser?.authToken || "";
        let partner = authUser?.partner || "";
        if (authToken) {
          const userDetails = parseJwt(authToken);
          setUserTokenDel(userDetails);
          if (!userDetails.masterId) {
            props.setLoading(true);
            const response = await getPartnerAction();
            if (response.status === 1) {
              let arr = response?.data || [];
              userTokenDel.masterId ? (arr = [arr]) : arr;
              setPartnerOption(arr);
              props.setLoading(false);
            } else {
              props.setLoading(false);
              // toast.error(response2.msg);
            }
          } else {
            setSelectedPartner(userDetails.masterId);
            await getClassificationOption(userDetails.masterId);
          }
        }
        if (partner) {
          setPartnerOption([partner]);
        }
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  useEffect(async () => {
    try {
      let response1 = await getUgListAction();
      if (response1.status === 1) {
        let arr = response1?.data || [];
        if (!id) {
          arr = arr.filter(val => val.status === 1);
        }
        setUserGroup(arr);
        setUserGroupOption(arr.filter(val => val.userType == values.userType));
      } else {
        // toast.error(response1.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      // toast.error(err);
    }
  }, [userTokenDel, values.userType]);

  useEffect(async () => {
    try {
      props.setLoading(true);
      let response1 = await getCountryListAction();
      let response2 = await getStateListAction();
      let response3 = await getCityListAction();
      if (response1.status === 1) {
        props.setLoading(false);
        let arr = response1?.data || [];
        setCountryId(arr);
      } else {
        toast.error(response1.msg);
        props.setLoading(false);
      }
      if (response2.status === 1) {
        props.setLoading(false);
        let arr = response2?.data || [];
        setStateId(arr);
      } else {
        toast.error(response2.msg);
        props.setLoading(false);
      }
      if (response3.status === 1) {
        props.setLoading(false);
        let arr = response3?.data || [];
        setCityId(arr);
      } else {
        toast.error(response3.msg);
        props.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  const getClassificationOption = async id => {
    try {
      props.setLoading(true);
      let response = await getClassificationAction(id);
      if (response.status === 1) {
        props.setLoading(false);
        const class1 = response.data?.Division || [];
        const class2 = response.data?.Brand || [];
        class1.unshift({ Division: "All" });
        class2.unshift({ Brand: "All" });
        setClass1Options(class1);
        setClass2Options(class2);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  const handleSubmit = async (values, action) => {
    try {
      if (values.class1.includes("All") && values.class1.length > 1) {
        toast.error(
          "You select All Classification 1. Please remove another selected"
        );
        return;
      }
      if (values.class2.includes("All") && values.class2.length > 1) {
        toast.error(
          "You select All Classification 2. Please remove another selected"
        );
        return;
      }
      props.setLoading(true);
      let response = await saveUserAction(
        { ...values, formId: getFormId() },
        id
      );
      if (response.status === 1) {
        props.setLoading(false);
        toast.success(response.msg);
        if (id) {
          history.push("/panel/user/list");
        }
        action.resetForm();
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  const handleChange = async (e, setValues, values) => {
    const fd = JSON.parse(JSON.stringify(values));
    let name = e.target?.name || "";
    let value = e.target?.value || "";
    fd[name] = value;
    if (name == "userType") {
      if (value == "Admin") {
        fd.partner = "";
      }
      fd.userGroup = "";
      const userTypeGroups = userGroup.filter(val => val.userType == value);
      setUserGroupOption(userTypeGroups);
    }
    if (name == "countryId") {
      fd.stateId = "";
      fd.cityId = "";
      setStateOption(stateId.filter(val => val.underId == value));
      setCityOption([]);
    }
    if (name == "partner") {
      setSelectedPartner(value);
    }
    if (name == "stateId") {
      setCityOption(cityId.filter(val => val.underId == value));
      fd.cityId = "";
    }
    if (
      (values.partner !== "" && value == "Vender") ||
      (values.userType == "Vender" && name == "partner")
    ) {
      let id = name == "partner" ? value : values.partner;
      await getClassificationOption(id);
    }
    setValues(fd);
  };
  return (
    <div className="page-content mb-4">
      <Container>
        {id && (
          <Breadcrumbs
            title="User"
            breadcrumbItem={"Edit User"}
            path="/panel/user/list"
          />
        )}

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={values}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, values, setValues }) => (
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="partner">
                              Select Partner
                              <span className=" text-danger"> *</span>
                            </Label>
                            <Field
                              as="select"
                              name="partner"
                              id="partner"
                              className={
                                "form-select" +
                                (errors.partner && touched.partner
                                  ? " is-invalid"
                                  : "")
                              }
                              disabled={
                                values.userType == "Admin" ||
                                userTokenDel.masterId
                                  ? true
                                  : false
                              }
                              onChange={e => handleChange(e, setValues, values)}
                            >
                              <option value={""}>Select partner</option>
                              {partnerOption.map(value => (
                                <option value={value.id} key={value.id}>
                                  {value.companyName}
                                </option>
                              ))}
                            </Field>
                            {errors.partner && touched.partner ? (
                              <p className="text-danger mt-1">
                                {errors.partner}
                              </p>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <div className="mt-4">
                            <h5 className="font-size-14 mb-1">
                              User type<span className=" text-danger"> *</span>
                            </h5>
                            <div className="d-flex">
                              {!userTokenDel.masterId && (
                                <div className="form-check me-2">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="userType"
                                    id="admin"
                                    value="Admin"
                                    onChange={e =>
                                      handleChange(e, setValues, values)
                                    }
                                    disabled={userTokenDel.masterId && true}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="admin"
                                  >
                                    Admin
                                  </label>
                                </div>
                              )}

                              <div className="form-check me-2">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="userType"
                                  id="Partner"
                                  value="Partner-User"
                                  onChange={e =>
                                    handleChange(e, setValues, values)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Partner"
                                >
                                  {userTokenDel.masterId
                                    ? "Admin"
                                    : "Partner user"}
                                </label>
                              </div>
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="userType"
                                  id="Vender"
                                  value="Vender"
                                  onChange={e =>
                                    handleChange(e, setValues, values)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Vender"
                                >
                                  Vender
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {values.userType === "Vender" && (
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="class1">
                                Classification 1{" "}
                                <span className=" text-danger">*</span>
                              </Label>
                              <Select
                                name="class1"
                                id="class1"
                                isMulti
                                placeholder="Select Classification 1"
                                getOptionLabel={e => e?.Division || "N/A"}
                                getOptionValue={e => e.Division}
                                value={values.class1.map(val => {
                                  return { Division: val };
                                })}
                                onChange={e => {
                                  handleChange(
                                    {
                                      target: {
                                        name: "class1",
                                        value: e.map(val => val.Division),
                                      },
                                    },
                                    setValues,
                                    values
                                  );
                                }}
                                options={class1Options}
                              />
                              {errors.class1 && (
                                <span className="text-danger">
                                  {errors.class1}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="class2">
                                Classification 2{" "}
                                <span className=" text-danger">*</span>
                              </Label>
                              <Select
                                name="class2"
                                id="class2"
                                isMulti
                                placeholder="Select Classification 2"
                                getOptionLabel={e => e.Brand || "N/A"}
                                getOptionValue={e => e.Brand}
                                value={values.class2.map(val => {
                                  return { Brand: val };
                                })}
                                onChange={e =>
                                  handleChange(
                                    {
                                      target: {
                                        name: "class2",
                                        value: e.map(val => val.Brand),
                                      },
                                    },
                                    setValues,
                                    values
                                  )
                                }
                                options={class2Options}
                              />
                              {errors.class2 && (
                                <span className="text-danger">
                                  {errors.class2}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="fName">
                              First Name <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="fName"
                              id="fName"
                              placeholder="Enter first Name"
                              maxLength={15}
                              className={
                                "form-control" +
                                (errors.fName && touched.fName
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="fName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="LastName">Last Name</Label>
                            <Field
                              type="text"
                              name="lName"
                              id="LastName"
                              placeholder="Enter last Name"
                              maxLength={15}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="email">
                              Email <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="Email"
                              name="email"
                              id="email"
                              placeholder="Enter email"
                              maxLength={50}
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="mobileNo">
                              Mobile Number{" "}
                              <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="mobileNo"
                              id="mobileNo"
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Enter Mobile Number"
                              maxLength={10}
                              className={
                                "form-control" +
                                (errors.mobileNo && touched.mobileNo
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="mobileNo"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {!id && (
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label for="password">
                                Password <span className=" text-danger">*</span>
                              </Label>
                              <Field
                                type={passInputType}
                                name="password"
                                id="password"
                                placeholder="Enter password"
                                maxLength={25}
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              {values.password && (
                                <span className="password-toggle-icon">
                                  {passIcon}
                                </span>
                              )}
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label for="cPassword">
                                Confirm password
                                <span className=" text-danger"> *</span>
                              </Label>
                              <Field
                                type={CInputType}
                                name="cPassword"
                                id="cPassword"
                                placeholder="Confirm Your password"
                                maxLength={25}
                                className={
                                  "form-control" +
                                  (errors.cPassword && touched.cPassword
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              {values.cPassword && (
                                <span className="password-toggle-icon">
                                  {cIcon}
                                </span>
                              )}
                              <ErrorMessage
                                name="cPassword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="userGroup">
                              Select User Group
                              <span className=" text-danger"> *</span>
                            </Label>
                            <Field
                              as="select"
                              name="userGroup"
                              id="userGroup"
                              className={
                                "form-select" +
                                (errors.userGroup && touched.userGroup
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value={""}>Select user group</option>
                              {userGroupOption.map(value => (
                                <option value={value.id} key={value.id}>
                                  {value.name}
                                </option>
                              ))}
                            </Field>
                            {errors.userGroup && touched.userGroup ? (
                              <p className="text-danger mt-1">
                                {errors.userGroup}
                              </p>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <div className="mt-4">
                            <h5 className="font-size-14 mb-1">
                              Status<span className=" text-danger"> *</span>
                            </h5>
                            <div className="d-flex">
                              <div className="form-check me-2">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="active"
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="active"
                                >
                                  Active
                                </label>
                              </div>
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inactive"
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inactive"
                                >
                                  Inactive
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <fieldset className="border border-3">
                          <legend className="float-none w-auto px-2">
                            Address
                          </legend>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for="address1">
                                  Address line 1{" "}
                                  <span className=" text-danger">*</span>
                                </Label>
                                <Field
                                  type="text"
                                  name="address1"
                                  id="address1"
                                  placeholder="Enter Address 1"
                                  maxLength={100}
                                  className={"form-control"}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="address1"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="address2">Address line 2</Label>
                                <Field
                                  type="text"
                                  name="address2"
                                  id="address2"
                                  placeholder="Enter Address 2"
                                  maxLength={100}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for="countryId">
                                  Country
                                  <span className=" text-danger"> *</span>
                                </Label>
                                <Select
                                  name="countryId"
                                  id="countryId"
                                  placeholder="Enter Country Name"
                                  value={
                                    id &&
                                    countryId.filter(
                                      obj => obj.id === values.countryId
                                    )[0]
                                  }
                                  getOptionLabel={e => e.name}
                                  getOptionValue={e => e.id}
                                  onChange={e =>
                                    handleChange(
                                      {
                                        target: {
                                          name: "countryId",
                                          value: e.id,
                                        },
                                      },
                                      setValues,
                                      values
                                    )
                                  }
                                  options={countryId}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="countryId"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="stateId">
                                  State <span className=" text-danger">*</span>
                                </Label>

                                <Select
                                  name="stateId"
                                  placeholder="Enter State Name"
                                  value={
                                    id &&
                                    stateId.find(
                                      obj => obj.id === values.stateId
                                    )
                                  }
                                  getOptionLabel={e => e.name}
                                  getOptionValue={e => e.id}
                                  onChange={e =>
                                    handleChange(
                                      {
                                        target: {
                                          name: "stateId",
                                          value: e.id,
                                        },
                                      },
                                      setValues,
                                      values
                                    )
                                  }
                                  options={stateOption}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="stateId"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label for="cityId">
                                  City <span className=" text-danger">*</span>
                                </Label>
                                <Select
                                  name="cityId"
                                  id="cityId"
                                  value={
                                    id &&
                                    cityId.find(obj => obj.id === values.cityId)
                                  }
                                  placeholder="Enter City"
                                  getOptionLabel={e => e.name}
                                  getOptionValue={e => e.id}
                                  onChange={e =>
                                    handleChange(
                                      {
                                        target: {
                                          name: "cityId",
                                          value: e.id,
                                        },
                                      },
                                      setValues,
                                      values
                                    )
                                  }
                                  options={cityOption}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="cityId"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label for="pinCode">
                                  Pin Code{" "}
                                  <span className=" text-danger">*</span>
                                </Label>
                                <Field
                                  type="text"
                                  name="pinCode"
                                  id="pinCode"
                                  placeholder="Enter Pin Code"
                                  maxLength={15}
                                  className="form-control"
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name="pinCode"
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>
                        </fieldset>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="d-flex my-4">
                            <label
                              className="form-check-label me-2 fw-bold"
                              htmlFor="appLogin"
                            >
                              Allow app login
                            </label>
                            <Field
                              type="checkbox"
                              name="appLogin"
                              id="appLogin"
                              label="Check the mark"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="remarks">Remark</Label>
                            <Field
                              as="textarea"
                              name="remarks"
                              placeholder="remarks"
                              id="remarks"
                              rows="10"
                              maxLength={300}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={{ size: 2, offset: 5 }}>
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loader(UserAdd);
