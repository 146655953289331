import { get, post, put, del } from "../../helpers/api_helper";

//start user and user-group api
export const getUgListAction = (id = "", query = "") => {
  if (id) {
    return get(`/user-groups/${id}`);
  } else {
    return get(`/user-groups?data=${query}`);
  }
};

export const saveUserGroupAction = (data, id = "") => {
  if (id) {
    return post(`/user-groups/${id}`, data);
  } else {
    return post(`/user-groups`, data);
  }
};

export const delUserGroupAction = async (data, id) => {
  return del(`/user-groups/${id}`, { data });
};

export const getUserAction = (id = "", query = "") => {
  if (id) {
    return get(`/user/${id}`);
  } else {
    return get(`/user?data=${query}`);
  }
};

export const saveUserAction = async (data, id = "") => {
  if (id) {
    return post(`/user/${id}`, data);
  } else {
    return post(`/user`, data);
  }
};

export const resetPassAction = async (data, id) => {
  return post(`/user/resetPassword/${id}`, data);
};

export const getFormPermissions = async () => {
  return get(`/formPermission`);
};

export const savePermission = async data => {
  return post(`/formPermission`, data);
};

export const getFormAction = async () => {
  return get(`/formData`);
};

export const changePassAction = async data => {
  return post(`/user/changePassword`, data);
};

//end user and user-group api

//start partners api

export const getCountryListAction = async (id = "", query = "") => {
  if (id) {
    return get(`/country/${id}`);
  } else {
    return get(`/country?data=${query}`);
  }
};
export const getStateListAction = async (id = "", query = "") => {
  if (id) {
    return get(`/state/${id}`);
  } else {
    return get(`/state?data=${query}`);
  }
};
export const getCityListAction = async (id = "", query = "") => {
  if (id) {
    return get(`/city/${id}`);
  } else {
    return get(`/city?data=${query}`);
  }
};

export const getMasterTypeListAction = async (id = "") => {
  if (id) {
    return get(`/master/${id}`);
  } else {
    return get(`/master`);
  }
};

export const saveCountryAction = async (data, id = "") => {
  if (id) {
    return post(`/country/${id}`, data);
  } else {
    return post(`/country`, data);
  }
};

export const saveStateAction = async (data, id = "") => {
  if (id) {
    return post(`/state/${id}`, data);
  } else {
    return post(`/state`, data);
  }
};

export const saveCityAction = async (data, id = "") => {
  if (id) {
    return post(`/city/${id}`, data);
  } else {
    return post(`/city`, data);
  }
};

export const savePartnerAction = async (data, id = "") => {
  if (id) {
    return post(`/partner/${id}`, data);
  } else {
    return post(`/partner`, data);
  }
};

export const getPartnerAction = (id = "", query = "") => {
  if (id) {
    return get(`/partner/${id}`);
  } else {
    return get(`/partner?data=${query}`);
  }
};

export const getUploadAction = async (id = "", query) => {
  if (id) {
    return get(`/upload/${id}?data=${query}`);
  } else {
    return get(`/upload?data=${query}`);
  }
};

export const saveUploadAction = async (data, id = "") => {
  if (id) {
    return post(`/upload/${id}`, data);
  } else {
    return post(`/upload`, data);
  }
};

export const getUploadDataListAction = async query => {
  return get(`/uploadData?data=${query}`);
};

export const delUploadDataListAction = async (data, id = "") => {
  if (id) {
    return del(`/uploadData/${id}`, { data });
  }
};

export const deleteUploadAction = async (data, id = "") => {
  if (id) {
    return del(`/upload/${id}`, { data });
  }
};

export const uploadDataLiveAction = async (data, id) => {
  if (id) {
    return post(`/uploadData/${id}`, data);
  }
};

//update profile

export const getProfileAction = async () => {
  return get(`/user/profile`);
};
export const uploadProfileImgAction = async data => {
  return post(`/user/updateProfileImg`, data);
};

export const uploadProfileAction = async data => {
  return post(`/user/updateProfile`, data);
};

export const getSummaryAction = async id => {
  if (id) {
    return get(
      `/dashboard/summary/${id}?type=1&type=2&type=3&type=4&type=6&type=7`
    );
  }
};
export const getPaySummary = async (id, date) => {
  return get(`/dashboard/paymentGraph/${id}/${date}`);
};

export const getChangeStatusListAction = (id, query) => {
  return get(`/data/${id}?data=${query}`);
};

export const changeStatusAction = async data => {
  return post("/change-status", data);
};

export const changeStatusHisAction = async (id, query) => {
  return get(`/change-status/history/${id}?data=${query}`);
};

export const saveReportAction = async (data, id = "") => {
  if (id) {
    return post(`/reports/master-views/${id}`, data);
  } else {
    return post(`/reports/master-views`, data);
  }
};

export const getReportAction = (id = "", query = "") => {
  if (id) {
    return get(`/reports/master-views/${id}`);
  } else {
    return get(`/reports/master-views?data=${query}`);
  }
};

export const forgetPwd = async data => {
  return post(`/forget-password`, data);
};

export const getChartsData = async (data = "") => {
  return get(`/reports/charts?data=${data}`);
};

export const getGraphData = async (data = "") => {
  const queryData = JSON.stringify(data);
  return get(`/reports/graph?data=${queryData}`);
};

export const getSalesReportData = async (
  id,
  multiSelect,
  query = "",
  brand
) => {
  if (brand) {
    return get(
      `/reports/sales-report/${id}/${brand}?multiSelect=${multiSelect}&&data=${query}`
    );
  } else {
    return get(
      `/reports/sales-report/${id}?multiSelect=${multiSelect}&&data=${query}`
    );
  }
};

export const getPivotReportData = async (id, voucher, data = "") => {
  if (id) {
    return get(`/reports/pivot-report/${id}/${voucher}?data=${data}`);
  } else {
    return get(`/reports/pivot-report/${voucher}?data=${data}`);
  }
};

export const getClassificationAction = async id => {
  return get(`/reports/classification/${id}`);
};

export const getBrandDetailsAction = async brand => {
  return get(`/reports/brand-details/${brand}`);
};

export const getVoucherType = async id => {
  if (id) {
    return get(`/reports/voucher-type/${id}`);
  } else {
    return get(`/reports/voucher-type`);
  }
};
