import Breadcrumbs from "components/Common/Breadcrumb";
import Loader from "components/Common/Loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { getFormId } from "helpers/common_helper";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import {
  getCountryListAction,
  saveCountry,
  saveCountryAction,
} from "store/actions";
import * as Yup from "yup";

const CountryAdd = props => {
  const { id } = useParams();
  const history = useHistory();
  const initialValues = {
    name: "",
    code: "",
    status: "1",
  };
  const [values, setValues] = useState(initialValues);
  const validationSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter country name"),
    code: Yup.string().min(2).max(100).required("Please enter country code"),
  });
  const handleSubmit = async (values, action) => {
    try {
      props.setLoading(true);
      let response = await saveCountryAction(
        {
          ...values,
          formId: getFormId(),
        },
        id
      );
      if (response.status === 1) {
        props.setLoading(false);
        toast.success(response.msg);
        if (id) {
          history.push("/panel/country/list");
        }
        action.resetForm();
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  useEffect(async () => {
    try {
      if (id) {
        props.setLoading(true);
        const response = await getCountryListAction(id);
        if (response.status === 1) {
          setValues({
            name: response.data.name,
            code: response.data.code,
            status: `${response.data.status}`,
          });
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [id]);
  return (
    <div className="page-content mb-4">
      <Container>
        {id && (
          <Breadcrumbs
            title="Country"
            breadcrumbItem={"Country Edit"}
            path="/panel/country/list"
          />
        )}

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={values}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(val, acc) => handleSubmit(val, acc)}
                >
                  {({ errors, touched, resetForm }) => (
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="name">
                              Country name{" "}
                              <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Enter Country name"
                              maxLength={100}
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="code">
                              Country code{" "}
                              <span className=" text-danger">*</span>
                            </Label>
                            <Field
                              type="text"
                              name="code"
                              id="code"
                              placeholder="Enter Country code"
                              maxLength={100}
                              className={
                                "form-control" +
                                (errors.code && touched.code
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="code"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="mt-4">
                            <h5 className="font-size-14 mb-1">
                              Status<span className=" text-danger"> *</span>
                            </h5>
                            <div className="d-flex">
                              <div className="form-check me-2">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="active"
                                  value="1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="active"
                                >
                                  Active
                                </label>
                              </div>
                              <div className="form-check">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  id="inactive"
                                  value="0"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inactive"
                                >
                                  Inactive
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ size: 2, offset: 5 }}>
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loader(CountryAdd);
